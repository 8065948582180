import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap'
import TextEditor from "../../components/Shared/components/TextEditor";
import { useParams, useSearchParams } from 'react-router-dom'
import CardLayout from '../../components/Layout/CardLayout'
import SelectMedia from '../../components/Shared/Media/SelectMedia'
import PageHeader from '../../components/Shared/Section/PageHeader'
import useToasts from '../../components/Toast/toastProvider'
import { DataContext } from '../../context/CustomGlobalContext'
import APIService from '../../utils/APIService'
import { capitalizeFirstLetter, getImageUrl } from '../../utils/Helper'
import SeoTab from '../../components/Shared/components2/SeoTab';

const CreateCategoryPage = () => {

  const { category } = useParams();
  const { toggleToast } = useToasts();
  const [searchParams] = useSearchParams();
  const { setPageData } = useContext(DataContext);
  const parent = searchParams.get('parent') || '';

  const defaultOverview = {
    head: '',
    subhead: '',
    image: ''
  };
  const [localdata2, setLocalData2] = useState(defaultOverview);
  const pagename = category;
  const imageId1 = `${category}_sectionImage1`;
  const imageId2 = `${category}_sectionImage2`;
  const [pageDataApiResponse, setPageDataApiResponse] = useState();
  const [seoData, setSeoData] = useState({
    seoTitle: '',
    seoKeywords: '',
    seoDescription: '',
  });
  const headerData = {
    mainHeading: 'Why Thailand',
    subHeadng: (category) ? capitalizeFirstLetter(category) : 'Overview',
    buttonText: ['Update']
  };

  useEffect(() => {
    setLocalData2(defaultOverview);
    fetchPageData();
  }, [category])

  const defaultSection = {
    details: "",
    align: "left",
    imageUrl: ""
  };

  const handleInputChange1 = (e, field, index) => {
    let pageData = { ...pageDataApiResponse };
    if (field === "checkbox") {
      pageData.sections[index] = { ...pageData.sections[index], [e.target.name]: e.target.checked };
    } else {
      pageData.sections[index] = { ...pageData.sections[index], [e.target.name]: e.target.value };
    }
    setPageDataApiResponse(pageData);
  };

  const fetchPageData = async () => {
    const res = await APIService.get(`/admin/page/item?pagename=${pagename}`);
    if (res.status === 200) {
      let apiResponse = res?.data?.data;
      let finalObj = {};
      if (apiResponse?.length) {
        let allData = apiResponse?.[0];
        let overviewData = (allData?.overview) ? allData?.overview : defaultOverview;
        setLocalData2(overviewData);
        if (allData?.seo) {
          setSeoData(allData?.seo);
        }
        allData[`${imageId1}`] = getImageUrl(allData?.overview?.image);
        if (!allData?.sections?.length) {
          allData.sections = [defaultSection];
        }
        finalObj = allData;
        allData.sections.map((val, i) => {
          allData[`${imageId2}-${i}`] = getImageUrl(val?.imageUrl);
        });
        setPageData(allData);
      }
      else {
        finalObj = {
          pagename: category,
          parentid: parent,
          overview: defaultOverview,
          sections: [defaultSection],
        }
      }
      setPageDataApiResponse(finalObj);
    }
  };

  const submitOverviewData = async () => {
    let payload = {
      pagename: category,
      parentid: parent,
    };
    payload.overview = localdata2;
    payload.seo = seoData;
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast('success', 'Page updated successfully ')
    } else {
      toggleToast('failure', 'Page not updated successfully ')
    }
  }

  const submitData = async () => {
    let payload = {
      pagename: category,
      parentid: parent,
    };
    payload.sections = pageDataApiResponse.sections;
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast("success", "Page updated successfully ");
    } else {
      toggleToast("failure", "Page not updated successfully ");
    }
  };

  const addNewSection = () => {
    let oldpageData = { ...pageDataApiResponse };
    let addNewSection = defaultSection
    let index = pageDataApiResponse?.sections.length;
    oldpageData[`${imageId1}-${index}`] = getImageUrl("");
    oldpageData.sections.push(addNewSection);
    setPageDataApiResponse(oldpageData);
    // setPageData({...pageData,sections:oldpageData})
  };

  const updatePageState = (updateSection, index) => {
    let pageData = { ...pageDataApiResponse };
    let updatedSection = updateSection(pageData.sections[index]);
    pageData.sections[index] = updatedSection;
    setPageDataApiResponse(pageData);
  };

  const handleInputChangeForEditor = (value, index) => {
    let pageData = { ...pageDataApiResponse };
    pageData.sections[index] = { ...pageData.sections[index], ['details']: value };
    setPageDataApiResponse(pageData);
  }

  const deleteSection = (index) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this section?"
    )
    if (confirmBox === true) {
      let oldpageData = { ...pageDataApiResponse };
      let sectionFilter = oldpageData.sections.filter((val, i) => (i !== index));
      oldpageData.sections = sectionFilter;
      setPageDataApiResponse(oldpageData);
    }
  }

  const deleteMedia = (mediaId, mediaName, index) => {
    pageDataApiResponse.sections[index][mediaName] = '';
    setPageData(prev => {
      return { ...prev, [mediaId]: '' }
    });
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={submitOverviewData}
      />
      <Tabs defaultActiveKey='main-content'>
        <Tab eventKey='main-content' title={`Why Thailand - ${(category) ? capitalizeFirstLetter(category) : 'Overview'}`}>
      <>
        <CardLayout>
          <Row>
            <Col md={8}>
              <Form>
                <Form.Group
                  className='mb-3'
                  controlId='exampleForm.ControlInput1'
                >
                  <Form.Label>Head</Form.Label>
                  <Form.Control
                    type='text'
                    value={localdata2?.head ? localdata2.head : ''}
                    name='head'
                    onChange={e => setLocalData2({ ...localdata2, ['head']: e.target.value })}
                  />
                </Form.Group>
                <Form.Group
                  className='mb-3'
                  controlId='exampleForm.ControlTextarea1'
                >
                  <Form.Label>Sub-head</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={2}
                    style={{ resize: 'none' }}
                    name='subhead'
                    value={localdata2?.subhead ? localdata2.subhead : ''}
                    onChange={e => setLocalData2({ ...localdata2, ['subhead']: e.target.value })}
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col
              md={3}
              className='d-flex justify-content-center align-self-center offset-md-1'
            >
              <SelectMedia
                type={'image'}
                name="image"
                id={imageId1}
                imgSrc={(localdata2?.image) ? localdata2?.image : ''}
                updateInputState={setLocalData2}
              />
            </Col>
          </Row>
        </CardLayout>
        <hr />
        <PageHeader
          buttonText={headerData.buttonText[0]}
          handleUpdate={submitData}
        />
        {pageDataApiResponse?.sections &&
          pageDataApiResponse?.sections.length > 0 &&
          pageDataApiResponse?.sections.map((localdata, index) => (
            <>
              <CardLayout>
                <Row>
                  <Col md={9}>
                    <Form>
                      <Form.Group
                        className='mb-3'
                        controlId='exampleForm.ControlInput1'
                      >
                        <Form.Label>Details</Form.Label>
                        <TextEditor
                          value={localdata.details ? localdata.details : ""}
                          handleInputChange={(value) => handleInputChangeForEditor(value, index)}
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col
                    md={3}
                    className='text-end'
                  >
                    <div className='row' >
                      <Form.Select
                        className='w-75 me-4'
                        name="align"
                        size="sm"
                        value={localdata?.align ? localdata.align : ''}
                        onChange={e => handleInputChange1(e, "align", index)}
                      >
                        <option disabled value="">
                          {" "}
                          --Align--{" "}
                        </option>
                        <option value="left">Left</option>
                        <option value="right">Right</option>
                      </Form.Select>
                      {localdata?.imageUrl &&
                        <button
                          type="button"
                          className="btn btn-danger btn-circle float-end"
                          title='Remove Media'
                          onClick={() => deleteMedia(`${imageId2}-${index}`, 'imageUrl', index)}
                        >
                          X
                        </button>
                      }
                    </div>
                    <div className='mt-5'>
                      <SelectMedia
                        type={'image'}
                        name="imageUrl"
                        id={`${imageId2}-${index}`}
                        imgSrc={(localdata?.imageUrl) ? localdata?.imageUrl : ''}
                        updateInputState={(data) => updatePageState(data, index)}
                      />
                    </div>
                  </Col>
                </Row>
                {
                  index && index > 0 && <Button onClick={e => deleteSection(index)} className="mt-3" variant="danger" >Delete Section</Button> || ''
                }
              </CardLayout>
            </>
          ))}

        <div className="add_section" onClick={addNewSection}>
          <span>add section</span> <span className="add_icon">+</span>
        </div>

      </>
      </Tab>
      <Tab eventKey='seo' title='SEO'>
          <SeoTab
            formInputs={seoData}
            handleInputChange={e => {
              let field = e.target?.name;
              let copyData = { ...seoData };
              copyData[field] = e.target?.value;
              setSeoData(copyData);
            }}
          />
        </Tab>
      </Tabs>
    </>
  )
}

export default CreateCategoryPage;
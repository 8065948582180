import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import CardLayout from "../../components/Layout/CardLayout";
import ImageSection from "../../components/Shared/components2/ImageSection";
import PageHeader from "../../components/Shared/Section/PageHeader";
import useToasts from "../../components/Toast/toastProvider";
import { DataContext } from "../../context/CustomGlobalContext";
import APIService from "../../utils/APIService";
import { capitalizeFirstLetter, getImageUrl } from "../../utils/Helper";
import CustomSelect from '../../components/Shared/components2/CustomSelect';
import TextEditor from "../../components/Shared/components/TextEditor";

export default function CreateSubCategory() {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { toggleToast } = useToasts();
  const { setPageData } = useContext(DataContext);

  const child = searchParams.get('row') || '';
  const slug = searchParams.get('slug') || '';
  const parent = searchParams.get('parent') || '';
  const isFromDestination = searchParams.get('isdestination') || false;

  const headerData = {
    mainHeading: 'Why Thailand',
    subHeadng: (slug) ? capitalizeFirstLetter(slug) : '',
    buttonText: ['Update']
  }
  const imageId1 = `createSubCategory_image1`;
  const imageId2 = `createSubCategory_image2`;

  const defaultSection = {
    location: "",
    title: "",
    details: "",
    isMediaChecked1: false,
    isVideoChecked1: false,
    isImageChecked1: true,
    imageUrl1: "",
    videoUrl1: "",
    isMediaChecked2: false,
    isVideoChecked2: false,
    isImageChecked2: true,
    imageUrl2: "",
    videoUrl2: ""
  };
  const [pageDataApiResponse, setPageDataApiResponse] = useState({});
  const [allAmbassadors, setAllAmbassadors] = useState([]);
  const [ambassadorOptions, setAmbassadorOptions] = useState([]);
  const [ambassadorProfile, setAmbassadorProfile] = useState([]);
  const [loadingPageData, setLoadingPageData] = useState(false);
  const [loadingAmbassadorData, setLoadingAmbassadorData] = useState(false);
  const [buttonState, setButtonState] = useState(false);

  useEffect(() => {
    commonFun();
  }, [loadingPageData, loadingAmbassadorData]);

  const commonFun = async () => {
    await fetchPageData();
    await fetchAmbassadors();
    if (pageDataApiResponse.ambassador && pageDataApiResponse.ambassador !== '') {
      if(Array.isArray(pageDataApiResponse.ambassador)){
        let profile = (pageDataApiResponse?.ambassador || []).map(am => {
          return allAmbassadors?.find(pro => pro?._id === am?.value);
        }).filter(Boolean);
        setAmbassadorProfile([...profile]);  
      }else{
        let profile = allAmbassadors.filter(pro => pro?._id === pageDataApiResponse?.ambassador.value);
        setAmbassadorProfile([profile[0]]);
      }
    }
  }

  const fetchPageData = async () => {
    const res = await APIService.get(`/admin/page/item?pagename=${slug}`);
    if (res.status === 200) {
      let allData = res.data?.data;
      let finalObj = {};
      if (allData?.length) {
        finalObj = allData?.[0];
        allData?.[0]?.sections?.map((val, i) => {
          allData[`${imageId1}-${i}`] = (val.isImageChecked1) ? getImageUrl(val?.imageUrl1) : getImageUrl(val?.videoUrl1);
          allData[`${imageId2}-${i}`] = (val.isImageChecked2) ? getImageUrl(val?.imageUrl2) : getImageUrl(val?.videoUrl2);
        });
        setPageData(allData);
      }
      else {
        finalObj = {
          pagename: slug,
          parentid: parent,
          sections: [defaultSection],
          ambassador: "",
          isAmbassadorChecked: false
        }
      }
      setPageDataApiResponse(finalObj);
      setLoadingPageData(true);
    }
  };

  const fetchAmbassadors = async () => {
    const res = await APIService.get(`/admin/convention/ambassadors`)
    if (res.status === 200) {
      let allData = res?.data?.data;
      setAllAmbassadors(allData);
      setLoadingAmbassadorData(true);
      let ambassadorsOptions = (allData?.length > 0 && allData?.map(e => ({ label: e?.name, value: e?._id })))
      setAmbassadorOptions(ambassadorsOptions);
    }
  }

  const submitData = async () => {
    let payload = { ...pageDataApiResponse };
    let data = {
      _id: child,
      ambassadors: ambassadorProfile?.map(a => a?._id)
    }
    const res = await APIService.put(`/admin/page/${slug}`, { payload });
    if (res.status === 200) {
      if (!isFromDestination) {
        robustIndustriesCRUD(data);
      }
      toggleToast("success", "Page updated successfully ");
      navigate(-1);
    } else {
      toggleToast("failure", "Page not updated successfully ");
    }
  };

  const robustIndustriesCRUD = async (data) => {
    const res = await APIService.post(`/admin/why-thailand/robust-industries`, data)
  }

  const handleInputChange = (e, field, index) => {
    let pageData = { ...pageDataApiResponse };
    if (field === "checkbox") {
      pageData.sections[index] = { ...pageData.sections[index], [e.target.name]: e.target.checked };
      setPageDataApiResponse(pageData);
      return;
    }
    else if (field === "isAmbassadorChecked") {
      pageData = { ...pageData, [e.target.name]: e.target.checked };
    }
    else if (field === "ambassador") {
      pageData = {...pageData, ambassador: [...e]};
      let profile = (e || []).map(am => {
        return allAmbassadors?.find(pro => pro?._id === am?.value);
      }).filter(Boolean);
      setAmbassadorProfile(prev => ([...profile]));
    }
    else {
      pageData.sections[index] = { ...pageData.sections[index], [e.target.name]: e.target.value };
    }
    setPageDataApiResponse(pageData);
  };

  const handleInputChangeForEditor = (value, index) => {
    let pageData = { ...pageDataApiResponse };
    pageData.sections[index] = { ...pageData.sections[index], ['details']: value };
    setPageDataApiResponse(pageData);
  }

  const updatePageState = (updateSection, index) => {
    let progressBar = updateSection();
    if (progressBar?.progressBar) {
      setButtonState(true);
      return
    }
    let pageData = { ...pageDataApiResponse };
    let updatedSection = updateSection(pageData.sections[index]);
    pageData.sections[index] = updatedSection;
    setPageDataApiResponse(pageData);
    setButtonState(false);
  };

  const deleteSection = (index) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this section?"
    )
    if (confirmBox === true) {
      let oldpageData = { ...pageDataApiResponse };
      let sectionFilter = oldpageData.sections.filter((val, i) => (i !== index));
      oldpageData.sections = sectionFilter;
      setPageDataApiResponse(oldpageData);
    }
  }

  const addNewSection = () => {
    let oldpageData = { ...pageDataApiResponse };
    let addNewSection = defaultSection
    let index = pageDataApiResponse?.sections.length;
    oldpageData[`${imageId1}-${index}`] = getImageUrl("");
    oldpageData.sections.push(addNewSection);
    setPageDataApiResponse(oldpageData);
    // setPageData({...pageData,sections:oldpageData})
  };

  return (
    <>

      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        buttonState={buttonState}
        handleUpdate={submitData}
      />

      {pageDataApiResponse?.sections &&
        pageDataApiResponse?.sections.length > 0 &&
        pageDataApiResponse?.sections.map((localdata, index) => (
          <>
            <CardLayout>
              {
                isFromDestination && index === 0 &&
                <Row>
                  <Col md={1}>
                    <Form.Group className="mb-3" controlId="location">
                      <Form.Label>Location</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md={9}>
                    <Form className="mb-3" controlId="location">
                      <Form.Control
                        type="text"
                        name="location"
                        value={localdata.location ? localdata.location : ""}
                        onChange={(e) => handleInputChange(e, "location", index)}
                      />
                    </Form>
                  </Col>
                  <Col md={2}></Col>
                </Row>
              }
              <Row>
                <Col md={1}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Title</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={9}>
                  <Form className="mb-3" controlId="title">
                    <Form.Control
                      type="text"
                      name="title"
                      value={localdata.title ? localdata.title : ""}
                      onChange={(e) => handleInputChange(e, "title", index)}
                    />
                  </Form>
                </Col>
                <Col md={2}></Col>
              </Row>
              <Row>
                <Col md={1}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Details</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={9}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <TextEditor
                      value={localdata.details ? localdata.details : ""}
                      handleInputChange={(value) => handleInputChangeForEditor(value, index)}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}></Col>
              </Row>
            </CardLayout>

            <CardLayout>
              <Row className="section-config">
                <Col className="offset-md-1" md={4}>
                  <ImageSection
                    localdata={localdata}
                    setLocalData={(updateSection) => updatePageState(updateSection, index)}
                    handleInputChange={(e, field) => handleInputChange(e, field, index)}
                    imageId={`${imageId1}-${index}`}
                    forImage={1}
                  />
                </Col>
                <Col className="offset-md-1" md={4}>
                  <ImageSection
                    localdata={localdata}
                    setLocalData={(updateSection) => updatePageState(updateSection, index)}
                    handleInputChange={(e, field) => handleInputChange(e, field, index)}
                    imageId={`${imageId2}-${index}`}
                    forImage={2}
                  />
                </Col>
              </Row>
              {
                index && index > 0 && <Button onClick={e => deleteSection(index)} className="mt-5" variant="danger" >Delete Section</Button> || ''
              }
            </CardLayout>
          </>
        ))}

      <div className="add_section" onClick={addNewSection}>
        <span>add section</span> <span className="add_icon">+</span>
      </div>

      <div className="mt-3">
        <CardLayout >
          <Form.Group className="mt-2 mb-3" controlId="forAmbassador">
            <div class="d-flex justify-content-between mb-2 row">
              <div class="section-primary col">Select Ambassador</div>
              <div class="d-flex justify-content-end align-items-center col">
                <input
                  type='checkbox'
                  name='isAmbassadorChecked'
                  className='cursor-pointer'
                  defaultChecked={pageDataApiResponse?.isAmbassadorChecked}
                  value={pageDataApiResponse?.isAmbassadorChecked}
                  onClick={e => handleInputChange(e, "isAmbassadorChecked")}
                />
              </div>
            </div>
            <CustomSelect
              options={ambassadorOptions || []}
              isMulti={true}
              onChange={(data) => handleInputChange(data, "ambassador")}
              // defaultOption={pageDataApiResponse?.ambassador}
              selectedOption={Array.isArray(pageDataApiResponse?.ambassador) ? pageDataApiResponse?.ambassador : [pageDataApiResponse?.ambassador]}
            />
          </Form.Group>

           {ambassadorProfile && ambassadorProfile.length > 0 && ambassadorProfile.map((ambassador, i) => {
            return <div className="mt-4">
              <Form.Label>Ambassador Details</Form.Label>
              <Row>
                <Col xs={3}>
                  <img src={getImageUrl(ambassador?.image)} alt="ambassador_image" width="200" height="200" />
                </Col>
                <Col>
                  <Container>
                    <p>
                      Name - {ambassador?.name}
                    </p>
                    <p>
                      Position - {ambassador?.position}
                    </p>
                    <p>
                      Details - {ambassador?.detailsambassadors}
                    </p>
                  </Container>
                </Col>
              </Row>
            </div>
            })
          } 
        </CardLayout>
      </div>

    </>
  );
}
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, ProgressBar, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import CardLayout from '../../components/Layout/CardLayout'
import SelectMedia from '../../components/Shared/Media/SelectMedia'
import PageHeader from '../../components/Shared/Section/PageHeader'
import useToasts from '../../components/Toast/toastProvider'
import { DataContext } from '../../context/CustomGlobalContext'
import APIService from '../../utils/APIService'
import { getImageUrl, emailValidator } from '../../utils/Helper'

export default function CACreate() {

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { toggleToast } = useToasts();
  const imageId = 'create_ambassador_image';
  const posterimageId = 'create_ambassador_posterimage';
  const videoId = 'create_ambassador_video';
  const urlId = searchParams.get("id");

  const { setPageData } = useContext(DataContext);
  const headerData = {
    mainHeading: 'Ambassadors',
    subHeadng: (urlId) ? 'Update' : 'Create',
    buttonText: []
  };

  let initialInputState = {
    name: "",
    email: "",
    position: "",
    institution: "",
    detailshome: "",
    detailsambassadors: "",
    category: "",
    image: "",
    videoUrl: "",
    poster: "",
    subcategory: "",
    targeturl: "",
    publish: false
  }
  const [formInputs, setFormInputs] = useState(initialInputState);
  const [categoryName, setCategoryName] = useState([]);
  const [loadind, setLoading] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const [progress, setProgress] = useState();

  const handleInputChange = (e) => {
    let key = e?.target?.name;
    let value = e?.target?.value;
    setFormInputs({ ...formInputs, [key]: value });
  }

  useEffect(() => {
    if (urlId) fetchPageData()
  }, [urlId])

  const fetchPageData = async () => {
    setLoading(true);
    const res = await APIService.get(`/admin/convention/ambassadors/${urlId}`)
    if (res.status === 200) {
      let allData = res?.data?.data?.[0];
      setFormInputs(allData);
      setPageData(prev => ({ ...prev, [imageId]: getImageUrl(allData?.image), [posterimageId]: getImageUrl(allData?.poster), [videoId]: getImageUrl(allData?.videoUrl) }));
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchCategoryName();
  }, [])

  const fetchCategoryName = async () => {
    const res = await APIService.get(`/admin/convention/category`)
    if (res.status === 200) {
      setCategoryName(res.data.data);
    }
  }

  const ambassadorCRUD = async (data) => {
    const res = await APIService.post(`/admin/convention/ambassadors`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      navigate('/convention-ambassadors/listing');
      setFormInputs(initialInputState);
      setPageData(prev => ({ ...prev, [imageId]: '', [videoId]: '' }));
    }
  }

  const addNewAmbassador = async () => {
    let validation = formValidation(formInputs);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...formInputs
      }
      ambassadorCRUD(data);
    }
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          if (
            key === 'subcategory' ||
            key === 'videoUrl' ||
            key === 'targeturl' ||
            key === 'categorytargeturl' ||
            key === 'email' ||
            key === 'detailsambassadors' ||
            key === 'detailshome'
          ) {
            continue;
          } else {
            if (obj.videoUrl) {
              if (!obj.poster) {
                toggleToast('danger', `Please enter ${key}`);
                return false;
              }
            } else {
              continue;
            }
            toggleToast('danger', `Please enter ${key}`);
            return false;
          }
        }
      }
    }
  }

  const updateMediaInputState = (data) => {
    let updatedUrl = data();
    if (updatedUrl?.progressBar) {
      setProgress(updatedUrl?.progressBar);
      setButtonState(true);
      return
    }
    setFormInputs(prev => ({ ...prev, videoUrl: updatedUrl?.videoUrl }));
    setButtonState(updatedUrl?.buttonState);
    setProgress();
  }

  const deleteMedia = (mediaId, mediaName, index) => {
    if (mediaName === 'videoUrl') {
      formInputs.videoUrl = '';
      formInputs.poster = '';
      setPageData(prev => {
        return { ...prev, [mediaId]: '', [posterimageId]: '' }
      });
      return
    }
    formInputs[mediaName] = '';
    setPageData(prev => {
      return { ...prev, [mediaId]: '' }
    });
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
      // buttonText={(formInputs?._id) ? "Update" : "Create New"}
      // handleUpdate={addNewAmbassador}
      />
      <div className="w-100">
        <Button
          className="me-1 float-end"
          onClick={addNewAmbassador}
          disabled={buttonState}
        >
          {(formInputs?._id) ? "Update" : "Create New"}
        </Button>
        <br />
        <br />
      </div>

      {
        (!loadind)
        &&
        <Row>
          <Col xs={9} >
            <CardLayout>
              <h1 className="page-header">

                <span>{urlId == null ? "New Ambassador" : "Edit Ambassador"}</span>
              </h1>

              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name='name'
                  type="text"
                  value={formInputs.name}
                  onChange={e => handleInputChange(e)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name='email'
                  type="email"
                  value={formInputs.email}
                  onChange={e => handleInputChange(e)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formPosition">
                <Form.Label>Position</Form.Label>
                <Form.Control
                  name="position"
                  type='text'
                  value={formInputs.position}
                  onChange={e => handleInputChange(e)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formInstitution">
                <Form.Label>Institution</Form.Label>
                <Form.Control
                  name="institution"
                  type='text'
                  value={formInputs.institution}
                  onChange={e => handleInputChange(e)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formHomePage">
                <Form.Label>Details [Home page]</Form.Label>
                <Form.Control
                  as="textarea"
                  style={{ resize: "none" }}
                  rows={2}
                  type='text'
                  name="detailshome"
                  value={formInputs.detailshome}
                  onChange={e => handleInputChange(e)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formAmbassadorsPage">
                <Form.Label>Details [Ambassadors page]</Form.Label>
                <Form.Control
                  as="textarea"
                  style={{ resize: "none" }}
                  rows={2}
                  type='text'
                  name="detailsambassadors"
                  value={formInputs.detailsambassadors}
                  onChange={e => handleInputChange(e)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formCategory">
                <Form.Label>Category</Form.Label>
                <Form.Select onChange={e => handleInputChange(e)} name="category" >
                  <option selected={(formInputs.category) ? false : true} disabled >Select...</option>
                  {
                    categoryName && categoryName.length > 0 && categoryName.map((val, i) => {
                      return (
                        <option key={i} value={val.name} selected={(val.name === formInputs.category) ? true : false} >{val.name}</option>
                      )
                    })
                  }
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formSubCategory">
                <Form.Label>Sub Category</Form.Label>
                <Form.Control
                  name="subcategory"
                  type='text'
                  value={formInputs.subcategory}
                  onChange={e => handleInputChange(e)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formStatus">
                <Form.Label>Status</Form.Label>
                <Form.Select onChange={e => handleInputChange(e)} name="publish" >
                  <option value={true} selected={(formInputs?.publish === true) ? true : false} >Active</option>
                  <option value={false} selected={(formInputs?.publish === false) ? true : false} >Inactive</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formRedirectionUrl">
                <Form.Label>Ambassador Info Redirection Url</Form.Label>
                <Form.Control
                  name='targeturl'
                  type="text"
                  value={formInputs.targeturl}
                  onChange={e => handleInputChange(e)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formCategoryRedirectionUrl">
                <Form.Label>Ambassador Category Redirection Url</Form.Label>
                <Form.Control
                  name='categorytargeturl'
                  type="text"
                  value={formInputs.categorytargeturl}
                  onChange={e => handleInputChange(e)}
                />
              </Form.Group>

              {/* <Button
                className="me-1 float-end"
                variant='primary'
                size='sm'
                onClick={addNewAmbassador}
              >
                {(formInputs?._id) ? "Update" : "Create New"}
              </Button> */}

            </CardLayout>
          </Col>

          <Col xs={3} >

            <Form.Group className="mb-3" controlId="formImage">
              <Form.Label>
                Image
              </Form.Label>
              {/* <button type="button" className="btn btn-danger btn-circle float-end" title='Remove Media' onClick={() => deleteMedia(imageId, 'image')} >X</button> */}
              <SelectMedia
                type={'image'}
                name="image"
                id={imageId}
                imgSrc={(formInputs?.image) ? formInputs?.image : ''}
                updateInputState={setFormInputs}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Video
                {/* <span>{(buttonState) ? "Uploading" : null}</span> */}
              </Form.Label>
              {progress && <ProgressBar animated now={progress} label={`${progress}%`} className='w-100 mb-2' />}

              {formInputs?.videoUrl &&
                <button
                  type="button"
                  className="btn btn-danger btn-circle float-end"
                  title='Remove Media'
                  onClick={() => deleteMedia(videoId, 'videoUrl')}
                >
                  X
                </button>
              }
              <SelectMedia
                type={'video'}
                name="videoUrl"
                id={videoId}
                imgSrc={(formInputs?.videoUrl) ? formInputs?.videoUrl : ''}
                updateInputState={updateMediaInputState}
              />
            </Form.Group>
            {
              formInputs?.videoUrl &&
              <Form.Group className="mb-3" controlId="formPosterImage">
                <Form.Label>
                  Thumbnail Image
                </Form.Label>
                {/* <button type="button" className="btn btn-danger btn-circle float-end" title='Remove Media' onClick={() => deleteMedia(posterimageId, 'poster')} >X</button> */}
                <SelectMedia
                  type={'image'}
                  name="poster"
                  id={posterimageId}
                  imgSrc={(formInputs?.poster) ? formInputs?.poster : ''}
                  updateInputState={setFormInputs}
                />
              </Form.Group>
            }

          </Col>
        </Row>
      }
    </>
  )
}
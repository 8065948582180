import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import CardLayout from '../../components/Layout/CardLayout';
import PageHeader from '../../components/Shared/Section/PageHeader';
import useToasts from '../../components/Toast/toastProvider';
import { DataContext } from '../../context/CustomGlobalContext';
import APIService from '../../utils/APIService';

import SectionBackground from '../../components/Shared/components2/SectionBackground';
import SectionButton from '../../components/Shared/components2/SectionButton';
import SectionImage from '../../components/Shared/components2/SectionImage';
import { getImageUrl } from '../../utils/Helper';
import SelectMedia from '../../components/Shared/Media/SelectMedia';
import TextEditor from '../../components/Shared/components/TextEditor';
import SeoTab from '../../components/Shared/components2/SeoTab';

const Gallery = () => {
  const { toggleToast } = useToasts();
  const { setPageData } = useContext(DataContext);

  const headerData = {
    mainHeading: 'Gallery',
    subHeadng: 'Overview',
    buttonText: ['Update'],
  };

  const pagename = 'galleryOverview';
  const imageId = 'gallery_overview_Image1';
  const imageId1 = 'gallery_overview_sectionImage';
  const bgImageId1 = 'gallery_overview_sectionBgImage';

  const defaultSection = {
    title: '',
    details: '',
    align: '',

    isMediaChecked: false,
    imageUrl: '',
    videoUrl: '',
    isImageChecked: true,
    isVideoChecked: false,

    backgroundUrl: '',
    backgroundColor: '',
    isBackgroundImageChecked: false,
    isBackgroundColorChecked: false,
    isBackgroundChecked: false,

    buttonUrl: '',
    buttonText: '',
    buttonAlign: '',
    buttonColor: '',
    isButtonChecked: false,
  };

  const [localdata2, setLocalData2] = useState({
    head: '',
    subhead: '',
    image: '',
  });
  const [seoData, setSeoData] = useState({
    seoTitle: '',
    seoKeywords: '',
    seoDescription: '',
  });
  const [pageDataApiResponse, setPageDataApiResponse] = useState();
  const [buttonState, setButtonState] = useState(false);

  useEffect(() => {
    fetchPageData();
  }, []);

  const fetchPageData = async () => {
    const res = await APIService.get(`/admin/page/item?pagename=${pagename}`);
    if (res.status === 200) {
      let allData = res.data?.data?.[0];

      setLocalData2(allData?.overview);
      if (allData?.seo) {
        setSeoData(allData?.seo);
      }
      let data = allData.sections?.[0];

      allData[`${imageId}`] = getImageUrl(allData?.overview?.image);

      setPageDataApiResponse(allData);
      allData.sections.map((val, i) => {
        allData[`${imageId1}-${i}`] = getImageUrl(val?.imageUrl);
        allData[`${bgImageId1}-${i}`] = getImageUrl(val?.backgroundUrl);
      });
      setPageData(allData);
    }
  };

  const updatePageState = (updateSection, index) => {
    let progressBar = updateSection();
    if (progressBar?.progressBar) {
      setButtonState(true);
      return;
    }
    let pageData = { ...pageDataApiResponse };
    let updatedSection = updateSection(pageData.sections[index]);
    pageData.sections[index] = updatedSection;
    setPageDataApiResponse(pageData);
    setButtonState(false);
  };

  const addNewSection = () => {
    let oldpageData = { ...pageDataApiResponse };
    let addNewSection = defaultSection;
    let index = pageDataApiResponse?.sections.length;
    oldpageData[`${imageId1}-${index}`] = getImageUrl('');
    oldpageData[`${bgImageId1}-${index}`] = getImageUrl('');
    oldpageData.sections.push(addNewSection);
    setPageDataApiResponse(oldpageData);
    // setPageData({...pageData,sections:oldpageData})
  };

  const submitData = async () => {
    let payload = { ...pageDataApiResponse };
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast('success', 'Page updated successfully ');
    } else {
      toggleToast('failure', 'Page not updated successfully ');
    }
  };

  const submitOverviewData = async () => {
    let payload = {};
    payload.overview = localdata2;
    payload.seo = seoData;

    // call the put api for the data update
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast('success', 'Page updated successfully ');
    } else {
      toggleToast('failure', 'Page not updated successfully ');
    }
  };

  const handleInputChange = (e, field, index) => {
    let pageData = { ...pageDataApiResponse };
    if (field === 'checkbox') {
      pageData.sections[index] = { ...pageData.sections[index], [e.target.name]: e.target.checked };
    } else {
      pageData.sections[index] = { ...pageData.sections[index], [e.target.name]: e.target.value };
    }
    setPageDataApiResponse(pageData);
  };

  const handleInputChangeForEditor = (value, index) => {
    let pageData = { ...pageDataApiResponse };
    pageData.sections[index] = { ...pageData.sections[index], ['details']: value };
    setPageDataApiResponse(pageData);
  };

  const deleteSection = index => {
    const confirmBox = window.confirm('Do you really want to delete this section?');
    if (confirmBox === true) {
      let oldpageData = { ...pageDataApiResponse };
      let sectionFilter = oldpageData.sections.filter((val, i) => i !== index);
      oldpageData.sections = sectionFilter;
      setPageDataApiResponse(oldpageData);
    }
  };

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={submitOverviewData}
      />
      <Tabs defaultActiveKey='main-content'>
        <Tab eventKey='main-content' title='Gallery'>
          <CardLayout>
            <Row>
              <Col md={8}>
                <Form>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Head</Form.Label>
                    <Form.Control
                      type='text'
                      value={localdata2?.head ? localdata2.head : ''}
                      name='head'
                      onChange={e => setLocalData2(pre => ({ ...pre, ['head']: e.target.value }))}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                    <Form.Label>Sub-head</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={2}
                      style={{ resize: 'none' }}
                      name='subhead'
                      value={localdata2?.subhead ? localdata2.subhead : ''}
                      onChange={e => setLocalData2(pre => ({ ...pre, ['subhead']: e.target.value }))}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col md={3} className='d-flex justify-content-center align-self-center offset-md-1'>
                <SelectMedia
                  type={'image'}
                  id={imageId}
                  name='image'
                  imgSrc={localdata2?.image ? localdata2?.image : ''}
                  updateInputState={setLocalData2}
                />
              </Col>
            </Row>
          </CardLayout>
        </Tab>
        <Tab eventKey='seo' title='SEO'>
          <SeoTab
            formInputs={seoData}
            handleInputChange={e => {
              let field = e.target?.name;
              let copyData = { ...seoData };
              copyData[field] = e.target?.value;
              setSeoData(copyData);
            }}
          />
        </Tab>
      </Tabs>
      <hr />

      <PageHeader buttonText={headerData.buttonText[0]} buttonState={buttonState} handleUpdate={submitData} />

      {pageDataApiResponse?.sections &&
        pageDataApiResponse?.sections.length > 0 &&
        pageDataApiResponse?.sections.map((localdata, index) => (
          <>
            <CardLayout>
              <Row>
                <Col md={1}>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Title</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={9}>
                  <Form className='mb-3' controlId='title'>
                    <Form.Control
                      type='text'
                      name='title'
                      value={localdata.title ? localdata.title : ''}
                      onChange={e => handleInputChange(e, 'title', index)}
                    />
                  </Form>
                </Col>
                <Col md={2}></Col>
              </Row>
              <Row>
                <Col md={1}>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                    <Form.Label>Details</Form.Label>
                  </Form.Group>
                </Col>
                <Col md={9}>
                  <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                    <TextEditor
                      value={localdata.details ? localdata.details : ''}
                      handleInputChange={value => handleInputChangeForEditor(value, index)}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}></Col>
              </Row>
            </CardLayout>

            <CardLayout>
              <Row className='section-config'>
                <Col className='offset-md-1' md={3}>
                  <SectionImage
                    localdata={localdata}
                    setLocalData={updateSection => updatePageState(updateSection, index)}
                    locationid={'gallery-index-media'}
                    handleInputChange={(e, field) => handleInputChange(e, field, index)}
                    imageId={`${imageId1}-${index}`}
                  />
                </Col>
                <Col className='offset-md-1' md={3}>
                  <SectionBackground
                    index={index}
                    localdata={localdata}
                    setLocalData={updateSection => updatePageState(updateSection, index)}
                    locationid={'gallery-index-background-media'}
                    handleInputChange={(e, field) => handleInputChange(e, field, index)}
                    bgImageId={`${bgImageId1}-${index}`}
                  />
                </Col>
                <Col className='offset-md-1' md={3}>
                  <SectionButton
                    localdata={localdata}
                    setLocalData={updateSection => updatePageState(updateSection, index)}
                    handleInputChange={(e, field) => handleInputChange(e, field, index)}
                  />
                </Col>
              </Row>
              {(index && index > 0 && (
                <Button onClick={e => deleteSection(index)} className='mt-5' variant='danger'>
                  Delete Section
                </Button>
              )) ||
                ''}
            </CardLayout>
          </>
        ))}

      <div className='add_section' onClick={addNewSection}>
        <span>add section</span> <span className='add_icon'>+</span>
      </div>
    </>
  );
};

export default Gallery;

import React, { useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import axios from "axios";
import { getImageUrl } from "../../../utils/Helper";

const TextEditor = ({ value, handleInputChange }) => {
  const editorRef = useRef(null);

  return (
    <Editor
      onInit={(evt, editor) => editorRef.current = editor}
      value={value}
      init={{
        height: 500,
        menubar: true,
        plugins: [
          'code','advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'fullscreen', 'insertdatetime', 'media', 'table', 'help', 'wordcount', 'numlist'
        ],
        font_formats:
          "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago;Kanit=font-light; Kanit Regular=font-regular; Kanit Bold=font-medium; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;",
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
        content_style: 'body { font-family:Kanit; font-size:14px }',
        selector: 'textarea',
        images_upload_url: `${process.env.REACT_APP_API_URL}/admin/page/uploadTinymce`,
        media_live_embeds: true,
        file_browser_callback_types: 'media',
        file_picker_callback: function (cb, value, meta) {
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.onchange = async function () {
            var file = this.files[0];
            let formdata = new FormData();
            formdata.append("media", file, file.name);
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/admin/page/uploadvideo`, formdata, { headers: { "Content-Type": "multipart/form-data" } })
            if (res.status === 200) {
              if (res?.data?.status === false) {
                console.log('not 200', res?.data?.message);
              }
              else {
                cb(getImageUrl(res.data.path));
              }
            } else {
              console.log('Failed in uploading BLOODY FILE')
            }
          };
          input.click();
        }
      }}
      onEditorChange={(val) => handleInputChange(val)}
    />
  );
};

export default TextEditor;
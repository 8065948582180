import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaEye, FaTelegramPlane } from 'react-icons/fa';
import SelectMedia from '../Media/SelectMedia';
import TextEditor from "../components/TextEditor"

const ModalComp = (props) => {

  const { handleInputChange, inputState, showModal, handleModal, formControls, CUD_API, Preview_API, imageId, videoId, thumbnailId, updateInputState, dropdownData } = props;

  const handleInputChangeForEditor = (value, type) => {
    if (type === 'details') {
      updateInputState((prev) => ({ ...prev, details: value }))
    } else {
      updateInputState((prev) => ({ ...prev, body: value }))
    }
  }

  return (
    <>
      <Modal size="lg" centered={true} show={showModal} onHide={handleModal}>
        <Modal.Body>

          {formControls.includes('New Robust Industry')
            &&
            <Modal.Title className='mb-3' >New Robust Industry</Modal.Title>
          }

          {formControls.includes('New Sustainability')
            &&
            <Modal.Title className='mb-3' >New Sustainability</Modal.Title>
          }

          {formControls.includes('Certification')
            &&
            <Modal.Title className='mb-3' >Certification</Modal.Title>
          }

          {formControls.includes('New Testimonial')
            &&
            <Modal.Title className='mb-3' >New Testimonial</Modal.Title>
          }

          {formControls.includes('Category')
            &&
            <Modal.Title className='mb-3' >Category</Modal.Title>
          }

          {formControls.includes('Convention Ambassadors Category')
            &&
            <>
              <Modal.Title className='mb-3' >Convention Ambassadors Category</Modal.Title>
              <Form.Control
                name="name"
                className="mb-3"
                value={(inputState?.name) ? inputState?.name : ''}
                onChange={e => handleInputChange(e)}
                placeholder="--- Name ---"
              ></Form.Control>
            </>
          }

          {formControls.includes('Our Team Category')
            &&
            <>
              <Modal.Title className='mb-3' >Our Team Category</Modal.Title>
              <Form.Control
                name="name"
                className="mb-3"
                value={(inputState?.name) ? inputState?.name : ''}
                onChange={e => handleInputChange(e)}
                placeholder="--- Name ---"
              ></Form.Control>
            </>
          }

          {formControls.includes('New Interview')
            &&
            <>
              <Modal.Title className='mb-3' >New Interview</Modal.Title>

              <Form.Select name='name' onChange={e => handleInputChange(e)} className="mb-3" aria-label="Default select example">
                <option disabled selected={(!inputState?.name) ? true : false} >Select Interviewer</option>
                {dropdownData && dropdownData.length > 0 && dropdownData.map((val, i) => {
                  return (
                    <option key={i} value={val} selected={(val === inputState?.name) ? true : false} >{val}</option>
                  )
                })}
              </Form.Select>

              <Form.Control
                name="question"
                className="mb-3"
                value={(inputState?.question) ? inputState?.question : ''}
                onChange={e => handleInputChange(e)}
                placeholder="Question ..."
              ></Form.Control>

              <Form.Control
                name="answer"
                as="textarea"
                rows={4}
                style={{ resize: "none" }}
                className="mb-3"
                placeholder='Answer ...'
                value={(inputState?.answer) ? inputState?.answer : ''}
                onChange={e => handleInputChange(e)}
              ></Form.Control>

            </>
          }

          <Container>
            <Row>
              <Col>
                {formControls.includes('name_noLabel')
                  &&
                  <Form.Group>
                    <Form.Control
                      name="name"
                      className="mb-3"
                      value={(inputState?.name) ? inputState?.name : ''}
                      onChange={e => handleInputChange(e)}
                      placeholder="Name ..."
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('title_noLabel')
                  &&
                  <Form.Group>
                    <Form.Control
                      name="industrytitle"
                      className="mb-3"
                      value={(inputState?.industrytitle) ? inputState?.industrytitle : ''}
                      onChange={e => handleInputChange(e)}
                      placeholder="Title ..."
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('targeturl_noLabel')
                  &&
                  <Form.Group>
                    <Form.Control
                      name="targeturl"
                      className="mb-3"
                      value={(inputState?.targeturl) ? inputState?.targeturl : ''}
                      onChange={e => handleInputChange(e)}
                      placeholder="Target URL ..."
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('targeturl_noLabel_disable')
                  &&
                  <Form.Group>
                    <Form.Control
                      disabled={(inputState._id) ? true : false}
                      name="targeturl"
                      className="mb-3"
                      value={(inputState?.targeturl) ? inputState?.targeturl : ''}
                      onChange={e => handleInputChange(e)}
                      placeholder="Target URL ..."
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('checkbox')
                  &&
                  <Form.Group>
                    <Form.Check
                      name="checkbox"
                      type="checkbox"
                      className='cursor-pointer'
                      label="(Redirect to URL)"
                      defaultChecked={inputState?.isredirectcheck}
                      onChange={e => handleInputChange(e)}
                    />
                  </Form.Group>
                }

                {formControls.includes('description_noLabel')
                  &&
                  <Form.Group>
                    <Form.Control
                      name="description"
                      className="mb-3"
                      value={(inputState?.description) ? inputState?.description : ''}
                      onChange={e => handleInputChange(e)}
                      placeholder="Description ..."
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('learnmorelink_noLabel')
                  &&
                  <Form.Group>
                    <Form.Control
                      name="learnmorelink"
                      className="mb-3"
                      value={(inputState?.learnmorelink) ? inputState?.learnmorelink : ''}
                      onChange={e => handleInputChange(e)}
                      placeholder="Learn More Link ..."
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('texteditor_noLabel')
                  &&
                  <Form.Group>
                    <TextEditor
                      value={(inputState?.details) ? inputState?.details : ''}
                      handleInputChange={(value) => handleInputChangeForEditor(value, 'details')}
                    />
                  </Form.Group>
                }

                {formControls.includes('slug_noLabel')
                  &&
                  <Form.Group>
                    <Form.Control
                      disabled={(inputState?._id) ? true : false}
                      name="slug"
                      placeholder="Slug ..."
                      className="mb-3"
                      value={(inputState?.slug) ? inputState?.slug : ''}
                      onChange={e => handleInputChange(e)}
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('body')
                  &&
                  <Form.Group>
                    <TextEditor
                      value={(inputState?.body) ? inputState?.body : ''}
                      handleInputChange={(value) => handleInputChangeForEditor(value, 'body')}
                    />
                  </Form.Group>
                }

                {formControls.includes('name')
                  &&
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      name="name"
                      className="mb-3"
                      value={(inputState?.name) ? inputState?.name : ''}
                      onChange={e => handleInputChange(e)}
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('slug')
                  &&
                  <Form.Group>
                    <Form.Label>Slug</Form.Label>
                    <Form.Control
                      disabled={(inputState?._id) ? true : false}
                      name="slug"
                      className="mb-3"
                      value={(inputState?.slug) ? inputState?.slug : ''}
                      onChange={e => handleInputChange(e)}
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('targeturl')
                  &&
                  <Form.Group>
                    <Form.Label>Target URL</Form.Label>
                    <Form.Control
                      name="targeturl"
                      className="mb-3"
                      value={(inputState?.targeturl) ? inputState?.targeturl : ''}
                      onChange={e => handleInputChange(e)}
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('targeturl_disable')
                  &&
                  <Form.Group>
                    <Form.Label>Target URL</Form.Label>
                    <Form.Control
                      disabled={(inputState?._id) ? true : false}
                      name="targeturl"
                      className="mb-3"
                      value={(inputState?.targeturl) ? inputState?.targeturl : ''}
                      onChange={e => handleInputChange(e)}
                    ></Form.Control>
                  </Form.Group>
                }
                {formControls.includes('displayGallery')
                  && <Row>
                    <Col sm={6}>
                      <Form.Check
                        type='checkbox'
                        label={` Show Testimonial in Video Gallery`}
                        // value={inputState?.displayGallery}
                        name="displayGallery"
                        defaultChecked={inputState?.displayGallery}
                        onChange={e => handleInputChange(e)}
                      />
                    </Col>
                  </Row>
                }

                {formControls.includes('status')
                  &&
                  <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <Form.Select name='publish' onChange={e => handleInputChange(e)} className="mb-3" aria-label="Default select example">
                      <option value={true} selected={(inputState.status === 'false') ? false : true} >On</option>
                      <option value={false} selected={(inputState.status === 'false') ? true : false} >Off</option>
                    </Form.Select>
                  </Form.Group>
                }

                {formControls.includes('category')
                  &&
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      name="category"
                      className="mb-3"
                      value={(inputState?.category) ? inputState?.category : ''}
                      onChange={e => handleInputChange(e)}
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('position')
                  &&
                  <Form.Group>
                    <Form.Label>Position</Form.Label>
                    <Form.Control
                      name="position"
                      className="mb-3"
                      value={(inputState?.position) ? inputState?.position : ''}
                      onChange={e => handleInputChange(e)}
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('email')
                  &&
                  <Form.Group>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      name="email"
                      className="mb-3"
                      value={(inputState?.email) ? inputState?.email : ''}
                      onChange={e => handleInputChange(e)}
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('details')
                  &&
                  <Form.Group>
                    <Form.Label>Details</Form.Label>
                    <Form.Control
                      name="details"
                      as="textarea"
                      rows={4}
                      style={{ resize: "none" }}
                      className="mb-3"
                      value={(inputState?.details) ? inputState?.details : ''}
                      onChange={e => handleInputChange(e)}
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('dyncamiCategory')
                  &&
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Form.Select
                      name='category'
                      onChange={e => handleInputChange(e)}
                      className="mb-3"
                      aria-label="Default select example"
                    >
                      <option
                        disabled
                        selected={(!inputState?.category) ? true : false}
                      >
                        Select Category ...
                      </option>
                      {
                        dropdownData
                        && dropdownData.length > 0
                        && dropdownData.map((val, i) => {
                          return (
                            <option
                              key={i}
                              value={val._id}
                              selected={(val._id === inputState?.category) ? true : false}
                            >
                              {val.name}
                            </option>
                          )
                        })
                      }
                    </Form.Select>
                  </Form.Group>
                }

                {formControls.includes('cname')
                  &&
                  <Form.Group>
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      name="cname"
                      className="mb-3"
                      value={(inputState?.cname) ? inputState?.cname : ''}
                      onChange={e => handleInputChange(e)}
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('country')
                  &&
                  <Form.Group>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      name="country"
                      className="mb-3"
                      value={(inputState?.country) ? inputState?.country : ''}
                      onChange={e => handleInputChange(e)}
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('shortNo')
                  &&
                  <Form.Group>
                    <Form.Label>Sort Order</Form.Label>
                    <Form.Control
                      type='number'
                      name="order"
                      className="mb-3"
                      value={(inputState?.order) ? inputState?.order : ''}
                      onChange={e => handleInputChange(e)}
                      placeholder="Only integers allowed ..."
                    ></Form.Control>
                  </Form.Group>
                }

                {formControls.includes('nofooter')
                  &&
                  <>
                    <div className='d-flex mt-4'>
                      <Button className='me-2' variant='success' onClick={Preview_API} ><FaEye className='me-2' />Preview</Button>
                      <Button className='me-2' onClick={CUD_API} ><FaTelegramPlane className='me-2' />Publish</Button>
                      <Button variant='secondary' size='sm' onClick={handleModal}>Cancel</Button>
                    </div>
                  </>
                }

              </Col>
              <Col>
                {formControls.includes('New Testimonial')
                  && <Row>
                    <Col sm={6}>
                      <Form.Check
                        type='radio'
                        label={`Image`}
                        value="image"
                        name="type"
                        checked={(inputState?.type !== "video") ? true : false}
                        onChange={e => handleInputChange(e)}
                      />
                    </Col>
                    <Col sm={6}>
                      <Form.Check
                        type='radio'
                        label={`Video`}
                        value="video"
                        name="type"
                        checked={(inputState?.type === "video") ? true : false}
                        onChange={e => handleInputChange(e)}
                      />
                    </Col>
                  </Row>
                }
                {inputState?.type !== "video" ? formControls.includes('image')
                  &&
                  <SelectMedia
                    type={'image'}
                    name='image'
                    id={imageId}
                    imgSrc={(inputState?.image) ? inputState?.image : ''}
                    updateInputState={updateInputState}
                  />
                  : formControls.includes('video')
                  && <>
                    <SelectMedia
                      type={'image'}
                      name="poster"
                      id={thumbnailId}
                      imgSrc={(inputState?.poster) ? inputState?.poster : ''}
                      updateInputState={updateInputState}
                    />
                    <SelectMedia
                      type={'video'}
                      name="videoUrl"
                      id={videoId}
                      imgSrc={(inputState?.videoUrl) ? inputState?.videoUrl : ''}
                      updateInputState={updateInputState}
                    />
                  </>}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {
            (!formControls.includes('nofooter')) ?
              <>
                {
                  (!inputState?._id)
                    ?
                    <Button className="me-1 float-start" variant="primary" onClick={CUD_API}>
                      Save
                    </Button>
                    :
                    <Button className="me-1 float-start" variant="primary" onClick={CUD_API}>
                      Update
                    </Button>
                }
                <Button
                  className="me-1"
                  variant='secondary'
                  size='sm'
                  onClick={handleModal}
                >
                  Cancel
                </Button>
              </>
              :
              null
          }
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalComp;
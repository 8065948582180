import React, { useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next/lib/src/bootstrap-table';
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaCopy, FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import CardLayout from '../../components/Layout/CardLayout';
import PageHeader from "../../components/Shared/Section/PageHeader";
import useToasts from '../../components/Toast/toastProvider';
import APIService from '../../utils/APIService';
import { getImageUrl, setRowIndex } from '../../utils/Helper'

export default function GalleryAlbums() {

  const navigate = useNavigate();
  const { toggleToast } = useToasts();

  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = setRowIndex(setTablePage, setSizePerPage);

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
      { dataField: "albumtitle", text: "Albums" },
      {
        dataField: "gallerytype",
        text: "Album Type",
      },
      {
        dataField: "visibility",
        text: "Visibility",
        // formatter: (cell, row) => (row.visibility) ? "Public" : "Private"
      },
      { dataField: "writer", text: "Writer" },
      { dataField: "displayPosition", text: "Display Position" },
      {
        dataField: "image",
        text: "Image",
        formatter: (cell, row) => {
          return (
            <img src={getImageUrl(row?.image)} alt='img' width="100" height="100" />
          )
        }
      },
      {
        dataField: "edit",
        text: 'Edit',
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteData(row)}
              />
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus(row)} defaultChecked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }

  const headerData = {
    mainHeading: 'Gallery',
    subHeadng: 'Albums',
    buttonText: ['New Album']
  }

  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter((person) =>
        person.albumtitle.toLowerCase().includes(search.toLowerCase()) ||
        person.writer.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filtered);
  }, [search])

  useEffect(() => {
    fetchTableData();
  }, [])

  const fetchTableData = async () => {
    const res = await APIService.get(`/admin/gallery/albums`)
    if (res.status === 200) {
      setTableData(res.data.data);
      setTempData(res.data.data);
    }
  }

  const galleryAlbumsCRUD = async (data) => {
    const res = await APIService.post(`/admin/gallery/albums`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData();
    }
  }

  const UpdateActiveStatus = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    galleryAlbumsCRUD(data);
  }

  const funDeleteData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      galleryAlbumsCRUD(data);
    }
  }

  const addNewAlbum = () => {
    navigate(`/gallery/create-albums`);
  }

  let funUpdateData = (rowData) => {
    navigate(`/gallery/create-albums?id=${rowData._id}`,{state:rowData._id});
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={addNewAlbum}
      />

      <CardLayout>
        <Form.Group >
          <Form.Control
            name="search"
            placeholder='Search...'
            value={search}
            onChange={e => setSearch(e.target.value)}
            className='mb-2 w-25 ms-auto'
          ></Form.Control>
        </Form.Group>

        <Row>
          <BootstrapTable className="table-rc" keyField='id' data={tableData} columns={buildCols()} pagination={paginationFactory(paginationOption)} />
        </Row>
      </CardLayout>

    </>
  )
}

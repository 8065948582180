import React, { useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CardLayout from '../../components/Layout/CardLayout';
import PageHeader from "../../components/Shared/Section/PageHeader";
import APIService from '../../utils/APIService';
import useToasts from "../../components/Toast/toastProvider";
import { getImageUrl, setRowIndex } from '../../utils/Helper';

export default function ConventionLineup() {

  const { toggleToast } = useToasts();
  const navigate = useNavigate();

  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = setRowIndex(setTablePage, setSizePerPage);

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
      {
        dataField: "contentsTitle",
        text: "Title",
        style: () => ({ maxWidth: '200px' })
      },
      {
        dataField: "city",
        text: "City",
        formatter: (cell, row) => (row?.city) ? row.city : "-"
      },
      {
        dataField: "targeturl",
        text: "Target URL",
        style: () => ({ maxWidth: '150px' })
      },
      {
        dataField: "featureImage",
        text: "Photo",
        formatter: (cell, row) => {
          return (
            <img src={getImageUrl(row?.featureImage)} alt='img' width="200" height="200" className="imageSize" />
          )
        }
      },
      {
        dataField: "publishedDate",
        text: "Date",
        formatter: (cell, row) => {
          let date = new Date(row.publishedDate);
          let final = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          return final;
        }
      },
      {
        dataField: "view",
        text: "View",
        formatter: (cell, row) => (row?.view) ? row?.view : 0
      },
      {
        dataField: "edit",
        text: 'Edit',
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteData(row)} />
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus(row)} defaultChecked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }

  const headerData = {
    mainHeading: 'Convention Lineup',
    subHeadng: '',
    buttonText: ['New Convention']
  };

  const [search, setSearch] = useState("");
  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter((person) =>
        person?.contentsTitle.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filtered);
  }, [search])

  useEffect(() => {
    fetchTableData();
  }, []);

  const fetchTableData = async () => {
    const res = await APIService.get(`/admin/contents/create/convention-lineup`)
    if (res.status === 200) {
      let data = res?.data?.data;
      setTableData(data);
      setTempData(res.data.data);
    }
  };

  const contentsCRUD = async (data) => {
    const res = await APIService.post(`/admin/contents/create`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData();
    }
  }

  let funUpdateData = (rowData) => {
    navigate(`/contents/create?id=${rowData._id}&category=convention-lineup`);
  }

  const funDeleteData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      contentsCRUD(data);
    }
  }

  const UpdateActiveStatus = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    contentsCRUD(data);
  }

  const navigateToContents = () => {
    navigate(`/contents/create?category=convention-lineup`);
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={navigateToContents}
      />

      <CardLayout>
        <Form.Group>
          <Form.Control
            name="search"
            placeholder="Search..."
            onChange={e => setSearch(e.target.value)}
            className='mb-2 w-25 ms-auto'
          ></Form.Control>
        </Form.Group>
        <Row>
          <BootstrapTable className="table-rc" keyField='id' data={tableData} columns={buildCols()} pagination={paginationFactory(paginationOption)} />
        </Row>
      </CardLayout>
    </>
  )
}
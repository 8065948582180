import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Container, Form, Modal, Row, Tabs, Tab } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import CardLayout from '../../components/Layout/CardLayout'
import ModalComp from '../../components/Shared/components/ModalComp'
import SelectMedia from '../../components/Shared/Media/SelectMedia'
import PageHeader from '../../components/Shared/Section/PageHeader'
import useToasts from '../../components/Toast/toastProvider'
import { DataContext } from '../../context/CustomGlobalContext'
import APIService from '../../utils/APIService'
import { getImageUrl, setRowIndex, targetUrlValidator } from '../../utils/Helper'
import TextEditor from "../../components/Shared/components/TextEditor";
import SeoTab from '../../components/Shared/components2/SeoTab'

const RobustIndustries = () => {

  const navigate = useNavigate();
  const { toggleToast } = useToasts();
  const { setPageData } = useContext(DataContext)
  const [localShowModal, setLocalShowModal] = useState(false)
  const headerData = {
    mainHeading: 'Why Thailand',
    subHeadng: 'Robust Industries (Overview Page)',
    buttonText: ['Update']
  }

  const [pageDataApiResponse, setPageDataApiResponse] = useState();
  const [allAmbassadors, setAllAmbassadors] = useState([]);
  const imageId1 = "robustIndustries_sectionImage1";
  const imageId2 = "robustIndustries_sectionImage2";
  const pagename = "whyThailandRobustIndustries";

  const [localdata, setLocalData] = useState({
    details: '',
    imageUrl: ''
  })

  const [localdata2, setLocalData2] = useState({
    head: '',
    subhead: '',
    image: ''
  })

  const [seoData, setSeoData] = useState({
    seoTitle: '',
    seoKeywords: '',
    seoDescription: '',
  });

  const fetchPageData = async () => {
    const res = await APIService.get(`/admin/page/item?pagename=${pagename}`)
    if (res.status === 200) {
      let allData = res.data?.data?.[0];
      let data = allData.sections?.[0];
      setPageDataApiResponse(allData);
      setLocalData2(allData?.overview);
      if (allData?.seo) {
        setSeoData(allData?.seo);
      }
      setLocalData(prev => ({ ...prev, ...data }))
      setPageData(prev => ({ ...prev, [imageId1]: getImageUrl(allData?.overview?.image), [imageId2]: getImageUrl(data?.imageUrl) }));
    }
  }

  const submitOverviewData = async () => {
    let payload = { ...pageDataApiResponse };
    payload.overview = localdata2;
    payload.seo = seoData;
    // call the put api for the data update
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast('success', 'Page updated successfully ')
    } else {
      toggleToast('failure', 'Page not updated successfully ')
    }
  }

  const submitData = async () => {
    let payload = { ...pageDataApiResponse };
    payload.sections = [{ ...localdata }];
    // call the put api for the data update
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast('success', 'Page updated successfully ')
    } else {
      toggleToast('failure', 'Page not updated successfully ')
    }
  }

  // -----------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------

  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = setRowIndex(setTablePage, setSizePerPage);

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
      { dataField: "industrytitle", text: "Robust Industries" },
      {
        dataField: "targeturl",
        text: "Target URL",
        formatter: (cell, row) => row.targeturl ? row.targeturl : '-',
        style: () => ({ maxWidth: '150px' })
      },
      {
        dataField: "image",
        text: "Image",
        formatter: (cell, row) => {
          return (
            <img src={getImageUrl(row?.image)} alt='img' width="100" height="100" />
          )
        }
      },
      {
        dataField: "visibility",
        text: "Status",
        formatter: (cell, row) => (row?.publish) ? 'Public' : 'Private'
      },
      {
        dataField: "ambassadors",
        text: "Ambassador",
        formatter: (cell, row) => {
          if (row.ambassadors.length) {
            return row.ambassadors.map((val, i) => {
              return filterAmbassadorsData(val)
            }).join(', ')
          }
          return "-";
        },
        style: () => ({ maxWidth: '100px' })
      },
      {
        dataField: "edit",
        text: 'Edit',
        formatter: (cell, row) => {
          return (
            <>
              <div className='mt-2' >
                <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
                <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteData(row)} />
              </div>
              {
                !row.visibility &&
                <div className='mt-2' >
                  <Link
                    size='sm'
                    to={`/why-thailand/subcategory?row=${row?._id}&slug=${row?.targeturl}&parent=${pageDataApiResponse?._id}`}
                    className='cursor-pointer'
                  >
                    Content
                  </Link>
                </div>
              }
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus(row)} defaultChecked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }

  const renderModal = () => {
    return (
      <Modal size="sm-down" centered={true} show={localShowModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>New Robust Industry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="justify-content-center">
              <Col>
                <Link
                  className="me-5 btn btn-success btn-lg"
                  to='/why-thailand/robust-industries/create'
                >
                  Create Page
                </Link>
              </Col>
              <Col>
                <Button
                  className="me-1"
                  variant='primary'
                  size='lg'
                  onClick={() => { handleModal2(); handleCancel() }}
                >
                  Redirect Page
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    )
  }

  const handleCancel = () => {
    setLocalShowModal(!localShowModal)
  }
  const formControls = ['name', 'image'];
  const formControls2 = ['New Robust Industry', 'title_noLabel', 'targeturl_noLabel_disable', 'checkbox', 'body', 'image'];
  const imageId = "robustIndustries_tableImage";
  const redirect_imageId = "robustIndustries_redirect_image";

  const [search, setSearch] = useState("");
  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  let initialInputState1 = {
    name: ""
  }
  const [modalInputs1, setModalInputs1] = useState(initialInputState1);

  let initialInputState2 = {
    industrytitle: "",
    targeturl: "",
    body: "",
    image: ""
  }
  const [modalInputs2, setModalInputs2] = useState(initialInputState2);

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter((person) =>
        person?.industrytitle.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filtered);
  }, [search])

  useEffect(() => {
    fetchTableData();
    fetchPageData();
    fetchAmbassadorsData();
  }, [])

  const fetchTableData = async () => {
    const res = await APIService.get(`/admin/why-thailand/robust-industries`)
    if (res.status === 200) {
      setTableData(res.data.data);
      setTempData(res.data.data);
    }
  }

  const fetchAmbassadorsData = async () => {
    const res = await APIService.get(`/admin/convention/ambassadors`)
    if (res.status === 200) {
      setAllAmbassadors(res?.data?.data);
    }
  }

  const robustIndustriesCRUD = async (data) => {
    const res = await APIService.post(`/admin/why-thailand/robust-industries`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData();
      setModalInputs1(initialInputState1);
      setPageData(prev => ({ ...prev, [imageId]: '' }))
      setShowModal2(false);
    }
    else {
      toggleToast('danger', res?.data?.message);
    }
  }

  let funUpdateData = (rowData) => {
    if (!rowData.visibility) {
      setModalInputs2(rowData);
      setShowModal2(!showModal2);
      setPageData(prev => ({ ...prev, [redirect_imageId]: getImageUrl(rowData?.image) }))
    }
    else {
      navigate(`/why-thailand/robust-industries/create?id=${rowData._id}`);
    }
  }

  const UpdateActiveStatus = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    robustIndustriesCRUD(data);
  }

  const funDeleteData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      robustIndustriesCRUD(data);
    }
  }

  const addNewRobustIndustry = async () => {
    let validation = formValidation(modalInputs1);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...modalInputs1
      }
      robustIndustriesCRUD(data);
    }
  }

  const addNewRobustIndustry1 = async () => {
    let validation = formValidation(modalInputs2);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...modalInputs2
      }
      robustIndustriesCRUD(data);
    }
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
      }
    }
  }

  const handleInputChange = (e) => {
    let key = e?.target?.name;
    let value = e?.target?.value;
    if (key === "search") {
      return setSearch(value);
    }
    setModalInputs1({ ...modalInputs1, [key]: value });
  }

  const handleModal1 = () => {
    setShowModal1(!showModal1);
    setModalInputs1(initialInputState1);
    setPageData(prev => ({ ...prev, [imageId]: '' }))
  }

  const handleModal2 = () => {
    setModalInputs2(initialInputState2);
    setShowModal2(!showModal2);
    setShowModal1(false);
    setPageData(prev => ({ ...prev, [redirect_imageId]: '' }))
  }

  const handleInputChange2 = (e) => {
    let key = e?.target?.name;
    let value = e?.target?.value;
    if (key === "checkbox") {
      setModalInputs2({ ...modalInputs2, isredirectcheck: e.target.checked });
    }
    else if (key === "targeturl") {
      let validValue = targetUrlValidator(value);
      if (validValue?.message !== "") {
        toggleToast('danger', validValue?.message);
      }
      return setModalInputs2({ ...modalInputs2, [key]: validValue.result });
    }
    else if (!key) {
      setModalInputs2({ ...modalInputs2, body: e });
    }
    else {
      setModalInputs2({ ...modalInputs2, [key]: value });
    }
  }

  const filterAmbassadorsData = (id) => {
    let localAmbassador = allAmbassadors.filter(val => val._id === id);
    return (localAmbassador.length) ? localAmbassador?.[0]?.name : "-";
  }

  const deleteMedia = (mediaId, mediaName, index) => {
    localdata[mediaName] = '';
    setPageData(prev => {
      return { ...prev, [mediaId]: '' }
    });
  }

  return (
    <>
      {/* Page Header */}
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={submitOverviewData}
      />
      <Tabs defaultActiveKey='main-content'>
        <Tab eventKey='main-content' title='Robust Industries'>
          <>
            <CardLayout>
              <Row>
                <Col md={8}>
                  <Form>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label>Head</Form.Label>
                      <Form.Control
                        type='text'
                        value={localdata2?.head ? localdata2.head : ''}
                        name='head'
                        onChange={e => setLocalData2({ ...localdata2, ['head']: e.target.value })}
                      />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Form.Label>Sub-head</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={2}
                        style={{ resize: 'none' }}
                        name='subhead'
                        value={localdata2?.subhead ? localdata2.subhead : ''}
                        onChange={e => setLocalData2({ ...localdata2, ['subhead']: e.target.value })}
                      />
                    </Form.Group>
                  </Form>
                </Col>
                <Col md={3} className='d-flex justify-content-center align-self-center offset-md-1'>
                  <SelectMedia
                    type={'image'}
                    name='image'
                    id={imageId1}
                    imgSrc={localdata2?.image ? localdata2?.image : ''}
                    updateInputState={setLocalData2}
                  />
                </Col>
              </Row>
            </CardLayout>

            <hr />

            {/* Page Header */}
            <PageHeader buttonText={headerData.buttonText[0]} handleUpdate={submitData} />
            <CardLayout>
              <Row>
                <Col md={8}>
                  <Form>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label>Details</Form.Label>
                      <TextEditor
                        value={localdata.details ? localdata.details : ''}
                        handleInputChange={value => setLocalData({ details: value })}
                      />
                    </Form.Group>
                  </Form>
                </Col>
                <Col md={3} className='align-self-center col-md-3 offset-md-1'>
                  {localdata?.imageUrl && (
                    <button
                      type='button'
                      className='btn btn-danger btn-circle float-end'
                      title='Remove Media'
                      onClick={() => deleteMedia(imageId2, 'imageUrl')}
                    >
                      X
                    </button>
                  )}
                  <div className='mt-5'>
                    <SelectMedia
                      type={'image'}
                      name='imageUrl'
                      id={imageId2}
                      imgSrc={localdata?.imageUrl ? localdata?.imageUrl : ''}
                      updateInputState={setLocalData}
                    />
                  </div>
                </Col>
              </Row>
            </CardLayout>

            <PageHeader buttonText='Add New' handleUpdate={() => setLocalShowModal(!localShowModal)} />

            {pageDataApiResponse?._id && (
              <CardLayout>
                <Form.Group>
                  <Form.Control
                    name='search'
                    type='text'
                    placeholder='Search...'
                    value={search}
                    onChange={e => handleInputChange(e)}
                    className='mb-2 w-25 ms-auto'
                  ></Form.Control>
                </Form.Group>

                <Row>
                  <BootstrapTable
                    className='table-rc'
                    keyField='id'
                    data={tableData}
                    columns={buildCols()}
                    pagination={paginationFactory(paginationOption)}
                  />
                </Row>
              </CardLayout>
            )}
            {localShowModal ? renderModal() : null}
          </>
        </Tab>
        <Tab eventKey='seo' title='SEO'>
              <SeoTab
                formInputs={seoData}
                handleInputChange={e => {
                  let field = e.target?.name;
                  let copyData = { ...seoData };
                  copyData[field] = e.target?.value;
                  setSeoData(copyData);
                }}
              />
            </Tab>
      </Tabs>

      {showModal1 ? (
        <ModalComp
          imageId={imageId}
          showModal={showModal1}
          CUD_API={addNewRobustIndustry}
          handleModal={handleModal1}
          formControls={formControls}
          inputState={modalInputs1}
          handleInputChange={handleInputChange}
          updateInputState={setModalInputs1}
        />
      ) : showModal2 ? (
        <ModalComp
          imageId={redirect_imageId}
          showModal={showModal2}
          CUD_API={addNewRobustIndustry1}
          handleModal={handleModal2}
          formControls={formControls2}
          inputState={modalInputs2}
          handleInputChange={handleInputChange2}
          updateInputState={setModalInputs2}
        />
      ) : null}
    </>
  );
}

export default RobustIndustries
import React, { useEffect, useState } from 'react'
import { Button, Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from 'react-csv';
import { FaFileExcel, FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import CardLayout from '../../components/Layout/CardLayout';
import ModalComp from '../../components/Shared/components/ModalComp';
import PageHeader from '../../components/Shared/Section/PageHeader';
import useToasts from '../../components/Toast/toastProvider';
import APIService from '../../utils/APIService';
import { emailValidator, setRowIndex } from '../../utils/Helper';

export default function NewsLetter() {

  const { toggleToast } = useToasts();

  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = setRowIndex(setTablePage, setSizePerPage);

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
     
      { dataField: "email", text: "E-mail" },
      { dataField: "created", text: "Date" },
      
    ]
    return columnFields;
  }

  const headerData = {
    mainHeading: 'Contact Us',
    subHeadng: 'NewsLetter Subscribe List',
    buttonText: []
  };

  const [search, setSearch] = useState();
  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  let initialInputState = {
    name: "",
    position: "",
    email: "",
    details: "",
    category: "",
    country: "",
    image: ""
  }
  const [modalInputs, setModalInputs] = useState(initialInputState);

  const formControls = ['email', 'created'];

  const csvHeaders = [
    { label: "E-mail", key: "email" },
    { label: "Date", key: "created" }
  ];

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter((person) =>
        person.email.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filtered);
  }, [search]);

  useEffect(() => {
    fetchTableData();
  }, []);

  const fetchTableData = async () => {
    const res = await APIService.get(`/contact-us/newsletter-subscribe`)
    console.log("res",res)
    if (res.status === 200) {
      let apiData = res?.data?.data;
      const data = apiData.map(row => {
        let date = new Date(row.created);
        let final = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        return (
          { ...row, created: final }
        )
      })
      setTableData(data);
      setTempData(data);
    }
  }

  const handleInputChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if (key === "filter") {
      return setSearch(value);
    }
    setModalInputs({ ...modalInputs, [key]: value });
  }

  const updateSubscriber = async () => {
    let validation = formValidation(modalInputs);
    if (validation !== false) {
      delete modalInputs.created;
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...modalInputs
      }
      handleModal();
    }
  }



  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
      }
      if (key === 'email') {
        if (!emailValidator(obj['email'])) {
          toggleToast('danger', `Please enter valid email.`)
          return false;
        }
      }
    }
  }

  const handleModal = () => {
    setShowModal(!showModal);
    setModalInputs(initialInputState);
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate=""
      />

      <CardLayout>

        <Button
          variant="outline-secondary"
          className="ms-2 float-end btn-outline-secondary"
          size='sm'
        >
          <CSVLink style={{ textDecoration: 'none' }} data={tableData} headers={csvHeaders}>
            <FaFileExcel /> Export
          </CSVLink>
        </Button>
     
        <Form.Group>
          <Form.Control
            name="filter"
            placeholder="Search..."
            value={search}
            onChange={e => handleInputChange(e)}
            className='mb-2 w-25 ms-auto'
            autoComplete="off" 
          ></Form.Control>
        </Form.Group>

        <Row>
          <BootstrapTable className="table-rc" keyField='id' data={tableData} columns={buildCols()} pagination={paginationFactory(paginationOption)} />
        </Row>

      </CardLayout>

      {
        showModal
          ?
          <ModalComp
            imageId=''
            showModal={showModal}
            CUD_API={updateSubscriber}
            handleModal={handleModal}
            formControls={formControls}
            inputState={modalInputs}
            handleInputChange={handleInputChange}
            updateInputState={setModalInputs}
          />
          :
          null
      }

    </>
  )
}

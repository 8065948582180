import React, { useEffect, useState } from 'react'
import { Form, Row } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { FaCopy, FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import CardLayout from '../../components/Layout/CardLayout'
import PageHeader from '../../components/Shared/Section/PageHeader'
import useToasts from '../../components/Toast/toastProvider'
import APIService from '../../utils/APIService'
import { setRowIndex } from '../../utils/Helper'

const CustomPages = () => {

  const navigate = useNavigate();
  const { toggleToast } = useToasts();
  const headerData = {
    mainHeading: 'Custom',
    subHeadng: 'Pages',
    buttonText: ['Add New Page']
  }

  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = setRowIndex(setTablePage, setSizePerPage);

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
      {
        dataField: "overview.pagetitle",
        text: "Page Title",
        formatter: (cell, row) => (row?.overview?.pagetitle) ? row?.overview?.pagetitle : '-'
      },
      {
        dataField: "slug",
        text: "Page link",
        formatter: (cell, row) => (row?.slug) ? `/pages/${row?.slug}` : '-',
        style: () => ({ maxWidth: '150px' })
      },
      {
        dataField: "slug",
        text: "Copy URL",
        formatter: (cell, row) => <FaCopy className='cursor-pointer' onClick={() => copyUrl(row)} />
      },
      {
        dataField: "visibility",
        text: "Status",
        formatter: (cell, row) => (row?.publish) ? 'Public' : 'Private'
      },
      {
        dataField: "view",
        text: "Views",
        formatter: (cell, row) => (row?.view) ? row?.view : 0
      },
      {
        dataField: "edit",
        text: 'Edit',
        formatter: (cell, row) => {
          return (
            <>
              <div className='mt-2' >
                <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
                <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteData(row)} />
              </div>
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus(row)} defaultChecked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }
  const [search, setSearch] = useState("");
  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter((person) =>
        person?.overview?.pagetitle?.toLowerCase().includes(search?.toLowerCase()) ||
        person?.overview?.slug?.toLowerCase().includes(search?.toLowerCase())
      );
    setTableData(filtered);
  }, [search])

  useEffect(() => {
    fetchTableData();
  }, [])

  const fetchTableData = async () => {
    const res = await APIService.get(`/admin/custom-page`)
    if (res.status === 200) {
      setTableData(res.data.data);
      setTempData(res.data.data);
    }
  }

  const pageCRUD = async (payload) => {
    const res = await APIService.put(`/admin/custom-page/create`, payload);
    if (res.status === 200) {
      toggleToast("success", "Page updated successfully ");
    } else {
      toggleToast("failure", "Page not updated successfully ");
    }
    fetchTableData();
  }

  let funUpdateData = (rowData) => {
    navigate(`/pages/page-content?id=${rowData._id}`);
  }

  const UpdateActiveStatus = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    pageCRUD(data);
  }

  const funDeleteData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      pageCRUD(data);
    }
  }

  const handleInputChange = (e) => {
    let key = e?.target?.name;
    let value = e?.target?.value;
    if (key === "search") {
      return setSearch(value);
    }
  }

  const copyUrl = (row) => {
    navigator.clipboard.writeText(`/pages/${row.slug}`);
    toggleToast('success', "Slug copied successfully ...");
  }

  const redirectToCreatePage = () => {
    navigate(`/pages/page-content`);
  }

  return (
    <>
      {/* Page Header */}
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={redirectToCreatePage}
      />

      <CardLayout>
        <Form.Group>
          <Form.Control
            name="search"
            placeholder="Search..."
            value={search}
            onChange={e => handleInputChange(e)}
            className='mb-2 w-25 ms-auto'
          ></Form.Control>
        </Form.Group>

        <Row>
          <BootstrapTable className="table-rc" keyField='id' data={tableData} columns={buildCols()} pagination={paginationFactory(paginationOption)} />
        </Row>
      </CardLayout>

    </>
  )
}

export default CustomPages
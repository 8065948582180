import React from 'react';
import { Form } from 'react-bootstrap';
import CardLayout from '../../Layout/CardLayout';

const SeoTab = ({ formInputs, handleInputChange }) => {
  return (
    <CardLayout>
      <Form>
        <Form.Group className='mb-3' controlId='seoTitle'>
          <Form.Label>Title</Form.Label>
          <Form.Control name='seoTitle' value={formInputs?.seoTitle} type='text' onChange={e => handleInputChange(e)} />
        </Form.Group>
        <Form.Group className='mb-3' controlId='seoKeywords'>
          <Form.Label>Keywords</Form.Label>
          <Form.Control
            name='seoKeywords'
            value={formInputs?.seoKeywords}
            type='text'
            onChange={e => handleInputChange(e)}
          />
        </Form.Group>
        <Form.Group className='mb-3' controlId='seoDescription'>
          <Form.Label>Description</Form.Label>
          <Form.Control
            name='seoDescription'
            value={formInputs?.seoDescription}
            as='textarea'
            rows={2}
            style={{ resize: 'none' }}
            onChange={e => handleInputChange(e)}
          />
        </Form.Group>
      </Form>
    </CardLayout>
  );
};

export default SeoTab;

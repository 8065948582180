import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, Row } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa'
import CardLayout from '../../components/Layout/CardLayout'
import ModalComp from '../../components/Shared/components/ModalComp'
import PageHeader from '../../components/Shared/Section/PageHeader'
import useToasts from '../../components/Toast/toastProvider'
import { DataContext } from '../../context/CustomGlobalContext'
import APIService from '../../utils/APIService'
import { getImageUrl, setRowIndex, generateSlug, targetUrlValidator } from '../../utils/Helper'

export default function Testimonials() {

  const { toggleToast } = useToasts();
  const { setPageData } = useContext(DataContext);
  let formControls = ['New Testimonial', 'name_noLabel', 'targeturl_noLabel', 'description_noLabel', 'learnmorelink_noLabel','displayGallery', 'image', 'poster', 'video'];

  const pagename = "successStoriesTestimonials";
  const imageId = "successStories_testimonial_tableImage";
  const videoId = "successStories_testimonial_tableVideo";
  const thumbnailId = "successStories_testimonial_tableThumbnail";

  const headerData = {
    mainHeading: 'Success Stories',
    subHeadng: 'Testimonials',
    buttonText: [""]
  }

  const [search, setSearch] = useState("");
  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const manageErrorForImage = {
    name: "name",
    // targeturl: "target url",
    // description: "description",
    // learnmorelink: "learn more link",
    image: "image"
  }
  const manageErrorForVideo = {
    name: "name",
    // description: "description",
    videoUrl: "video url",
    poster: "video thumbnail"
  }
  let initialInputState = {
    name: "",
    targeturl: "",
    description: "",
    displayGallery:"",
    learnmorelink: "",
    image: "",
    videoUrl: "",
    poster: "",
    type: "",
    status: "Public"
  }
  const [modalInputs, setModalInputs] = useState(initialInputState);
  const [localdata2, setLocalData2] = useState({
    head: "",
    subhead: ""
  })
  const [pageDataApiResponse, setPageDataApiResponse] = useState();

  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = setRowIndex(setTablePage, setSizePerPage);

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
      {
        dataField: "name",
        text: "Testimonials",
        style: () => ({ maxWidth: '150px' })
      },
      {
        dataField: "targeturl",
        text: "Target URL",
        style: () => ({ maxWidth: '150px' })
      },
      {
        dataField: "displayGallery",
        text: "Display Gallery",
        style: () => ({ maxWidth: '150px' })
      },
      {
        dataField: "type",
        text: "Type",
        style: () => ({ maxWidth: '100px' }),
        formatter: (cell, row) => {
          return (
            <span>{row.type !== 'video' ? 'Image' : 'Video'}</span>
          )
        }
      },
      {
        dataField: "image",
        text: "Image",
        formatter: (cell, row) => {
          return (
            <img src={getImageUrl(row.type !== 'video' ? row?.image : row?.poster)} alt='img' width="100" height="100" />
          )
        }
      },
      {
        dataField: "status",
        text: "Status",
        formatter: (cell, row) => (row?.publish) ? "Public" : "Private"
      },
      {
        dataField: "created",
        text: "Date",
        formatter: (cell, row) => {
          let date = new Date(row.created);
          let final = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          return final;
        }
      },
      {
        dataField: "edit",
        text: 'Edit',
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteData(row)} />
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus(row)} defaultChecked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }

  useEffect(() => {
    fetchTableData();
    fetchPageData();
  }, [])

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter((person) =>
        person.name.toLowerCase().includes(search.toLowerCase()) ||
        person.targeturl.toLowerCase().includes(search.toLowerCase())
      );
    setTableData(filtered);
  }, [search])

  const fetchPageData = async () => {
    const res = await APIService.get(`/admin/page/item?pagename=${pagename}`)
    if (res.status === 200) {
      let allData = res.data?.data?.[0];
      let data = allData.sections?.[0];
      setPageDataApiResponse(allData);
      setPageData(allData);
      setLocalData2(allData?.overview);
    }
  }

  const fetchTableData = async () => {
    const res = await APIService.get(`/admin/success-stories/testimonials`)
    if (res.status === 200) {
      setTableData(res.data.data);
      setTempData(res.data.data);
    }
  }

  const testimonialsCRUD = async (data) => {
    const res = await APIService.post(`/admin/success-stories/testimonials`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData();
    }
  }

  const addNewTestimonial = async () => {
    let validation = formValidation(modalInputs);
    if (validation !== false) {
      
      let newSlug = generateSlug(modalInputs?.name);
      let validSlug = targetUrlValidator(newSlug);
      let nslug = validSlug.result;      

      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        "slug" : modalInputs?.slug ? modalInputs.slug : nslug,
        ...modalInputs
      }
      testimonialsCRUD(data);
      handleModal();
    }
  }

  let funUpdateData = (rowData) => {
    setModalInputs(rowData);
    setShowModal(!showModal);
    setPageData(prev => ({ ...prev, [imageId]: getImageUrl(rowData?.image), [videoId]: getImageUrl(rowData?.videoUrl), [thumbnailId]: getImageUrl(rowData?.poster) }))
  }

  const funDeleteData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      testimonialsCRUD(data);
    }
  }

  const UpdateActiveStatus = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    testimonialsCRUD(data);
  }

  const handleInputChange = (e) => {
    let key = e?.target?.name;
    let value = e?.target?.value;
    let type = e?.target?.type;
    if(type === "checkbox"){
      value = e.target?.checked;
    }
    if (key === "search") {
      return setSearch(value);
    }
    setModalInputs({ ...modalInputs, [key]: value });
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
           if (manageErrorForImage.hasOwnProperty(key) && obj['type'] !== 'video') {
             toggleToast('danger', `Please enter ${manageErrorForImage[key]}`)
             return false;
           }
           else if(manageErrorForVideo.hasOwnProperty(key) && obj['type'] === 'video'){
            toggleToast('danger', `Please enter ${manageErrorForVideo[key]}`)
            return false;
           }
        }
      }
    }
  }

  const handleModal = () => {
    setShowModal(!showModal);
    setModalInputs(initialInputState);
    setPageData(prev => ({ ...prev, [imageId]: '', [videoId]: '', [thumbnailId]: '' }))
  }

  const handleOverviewInputChange = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    setLocalData2({ ...localdata2, [key]: value });
  }

  const submitOverviewData = async () => {
    let payload = { ...pageDataApiResponse };
    payload.overview = localdata2;
    // call the put api for the data update
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast('success', 'Page updated successfully ')
    } else {
      toggleToast('failure', 'Page not updated successfully ')
    }
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate=''
      />

      <div className="w-100" >
        <Button
          className="me-1 float-end"
          variant='primary'
          size='sm'
          onClick={submitOverviewData}
        >
          Update
        </Button>
        <br />
        <br />
      </div>

      <CardLayout>
        <Row>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Label>Head</Form.Label>
              <Form.Control
                type="text"
                name="head"
                value={localdata2?.head ? localdata2.head : ''}
                onChange={e => setLocalData2({ ...localdata2, ['head']: e.target.value })}

              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                style={{ resize: "none" }}
                name="subhead"
                value={localdata2?.subhead ? localdata2.subhead : ''}
                onChange={e => handleOverviewInputChange(e)}
              />
            </Form.Group>
          </Form>
        </Row>
      </CardLayout>
      <hr />

      <div className="w-100" >
        <Button
          className="me-1 float-end"
          variant='primary'
          size='sm'
          onClick={handleModal}
        >
          Add New
        </Button>
        <br />
        <br />
      </div>

      <CardLayout>
        <Form.Group >
          <Form.Control
            name="search"
            placeholder='Search...'
            value={search}
            onChange={e => handleInputChange(e)}
            className='mb-2 w-25 ms-auto'
          ></Form.Control>
        </Form.Group>

        <Row>
          <BootstrapTable className="table-rc" keyField='id' data={tableData} columns={buildCols()} pagination={paginationFactory(paginationOption)} />
        </Row>
      </CardLayout>

      {
        showModal
          ?
          <ModalComp
            imageId={imageId}
            videoId={videoId}
            thumbnailId={thumbnailId}
            showModal={showModal}
            CUD_API={addNewTestimonial}
            handleModal={handleModal}
            formControls={formControls}
            inputState={modalInputs}
            handleInputChange={handleInputChange}
            updateInputState={setModalInputs}
          />
          :
          null
      }

    </>
  )
}
import React, { useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FaCopy, FaRegEdit, FaTrashAlt } from 'react-icons/fa';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import CardLayout from '../../components/Layout/CardLayout';
import PageHeader from "../../components/Shared/Section/PageHeader";
import APIService from '../../utils/APIService';
import useToasts from "../../components/Toast/toastProvider";
import { capitalizeFirstLetter, setRowIndex } from '../../utils/Helper';

export default function Contents() {

  const { toggleToast } = useToasts();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const category = searchParams.get('category') || '';
  const { state } = useLocation();
  const [loading, setLoading] = useState(true);
  const [tablePage, setTablePage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const paginationOption = { ...setRowIndex(setTablePage, setSizePerPage), page: tablePage, sizePerPage: sizePerPage };

  const buildCols = () => {
    let columnFields = [
      {
        dataField: "id",
        text: "ID",
        formatter: (cell, row, rowIndex) => {
          return ((tablePage - 1) * sizePerPage + rowIndex + 1);
        },
      },
      {
        dataField: "contentsTitle",
        text: "Contents",
        style: () => ({ maxWidth: '250px' })
      },
      {
        dataField: "targeturl",
        text: "Copy URL",
        formatter: (cell, row) => <FaCopy className='cursor-pointer' onClick={() => copyUrl(row)} />
      },
      {
        dataField: "visibility",
        text: "Status",
        formatter: (cell, row) => row?.visibility ? capitalizeFirstLetter(row?.visibility) : 0
      },
      {
        dataField: "category",
        text: "Category",
        formatter: (cell, row) => {
          if (row.category.length) {
            return row.category.map((val, i) => {
              return (
                <li key={i} style={{ listStyleType: "none" }} >{val.label}</li>
              )
            })
          }
          return "-";
        }
      },
      {
        dataField: "publishedDate",
        text: "Publish Date",
        formatter: (cell, row) => {
          let date = new Date(row.publishedDate);
          let final = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          return final;
        }
      },
      {
        dataField: "created",
        text: "Created Date",
        formatter: (cell, row) => {
          let date = new Date(row.created);
          let final = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
          return final;
        }
      },
      {
        dataField: "view",
        text: "View",
        formatter: (cell, row) => (row?.view) ? row?.view : 0
      },
      {
        dataField: "edit",
        text: 'Edit',
        formatter: (cell, row) => {
          return (
            <>
              <FaRegEdit className='me-3 cursor-pointer' onClick={() => funUpdateData(row)} />
              <FaTrashAlt className='cursor-pointer' onClick={() => funDeleteData(row)} />
            </>
          )
        }
      },
      {
        dataField: "publish",
        text: 'Publish',
        formatter: (cell, row) => {
          return (
            <input className='cursor-pointer' type='checkbox' name='checkbox' onChange={e => UpdateActiveStatus(row)} defaultChecked={row.publish} />
          )
        }
      },
    ]
    return columnFields;
  }

  const headerData = {
    mainHeading: 'Contents',
    subHeadng: (category) ? capitalizeFirstLetter(category) : 'Overview',
    buttonText: ['New Content']
  };

  const [search, setSearch] = useState("");
  const [tempData, setTempData] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const filtered = !search
      ? tempData
      : tempData.filter(person => {
          let categoryIndex = person?.category?.findIndex(x => x?.value?.includes(search.toLowerCase()) || x?.label?.includes(search.toLowerCase())) ?? -1;
          return person?.contentsTitle.toLowerCase().includes(search.toLowerCase()) || categoryIndex !== -1;
        });
    setTableData(filtered);
  }, [search])

  useEffect(() => {
    if (state) {
      setTablePage(state.tablePage);
      setSizePerPage(state.sizePerPage);
    }
    else {
      setTablePage(1);
      setSizePerPage(10);
    }
    fetchTableData();
  }, [category]);

  const fetchTableData = async () => {
    const res = await APIService.get(`/admin/contents/create/${category}`)
    if (res.status === 200) {
      let data = res?.data?.data;
      setTableData(data);
      setTempData(res.data.data);
    }
    setLoading(false);
  };

  const contentsCRUD = async (data) => {
    const res = await APIService.post(`/admin/contents/create`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      fetchTableData();
    }
  }

  let funUpdateData = (rowData) => {
    if (category) {
      navigate(`/contents/create?id=${rowData._id}&category=${category}`, { state: { tablePage, sizePerPage } });
    }
    else {
      navigate(`/contents/create?id=${rowData._id}`, { state: { tablePage, sizePerPage } });
    }
  }

  const funDeleteData = (row) => {
    const confirmBox = window.confirm(
      "Do you really want to delete?"
    )
    if (confirmBox === true) {
      let data = {
        _id: row._id,
        isDeleted: true
      }
      contentsCRUD(data);
    }
  }

  const UpdateActiveStatus = (rowData) => {
    let data = {
      _id: rowData._id,
      publish: !rowData.publish,
    }
    contentsCRUD(data);
  }

  const copyUrl = (row) => {
    navigator.clipboard.writeText(row.targeturl);
    toggleToast('success', "Redirect Url copied successfully ...");
  }

  const navigateToContents = () => {
    if (category) {
      navigate(`/contents/create?category=${category}`);
    }
    else {
      navigate(`/contents/create`);
    }
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={navigateToContents}
      />

      <CardLayout>
        <Form.Group>
          <Form.Control
            name="search"
            placeholder="Search..."
            value={search}
            onChange={e => setSearch(e.target.value)}
            className='mb-2 w-25 ms-auto'
          ></Form.Control>
        </Form.Group>
        <Row>
          {
            loading === false &&
            <BootstrapTable className="table-rc" keyField='id' data={tableData} columns={buildCols()} pagination={paginationFactory(paginationOption)} />
          }
        </Row>
      </CardLayout>

    </>
  )
}
import React, { useContext, useEffect, useLayoutEffect, useState, useCallback, createRef } from "react";
import { Button, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { FaEye, FaTelegramPlane, FaTrashAlt } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import CardLayout from "../../components/Layout/CardLayout";
import { CompData } from "../../components/Rawdata/TempCompData";
import SelectMedia from "../../components/Shared/Media/SelectMedia";
import PageHeader from "../../components/Shared/Section/PageHeader";
import Suspense from "../../components/Shared/spinner/suspender";
import useToasts from "../../components/Toast/toastProvider";
import { DataContext } from "../../context/CustomGlobalContext";
import APIService from "../../utils/APIService";
import { useSearchParams } from 'react-router-dom'
import axios from "axios";
import { getComponentItemId, parseUrl, getImageUrl, generateSlug, getCurrentDate, targetUrlValidator } from "../../utils/Helper";
import TextEditor from "../../components/Shared/components/TextEditor"

const CreateAlbums = () => {
  const{state}=useLocation()
  const { pagedata, setPageData, mainData } = useContext(DataContext)
  const [pageSpecificComponent, setPageSpecificComponent] = useState([])

  const [searchParams] = useSearchParams();
  const [uploadfiles, setUploadFiles] = useState([])
  let caption = createRef();
  const location = useLocation();
  let navigate = useNavigate();
  const { toggleToast } = useToasts();
  const imageId = "album-media";

  const cmpName = parseUrl(location, 'absolute')
  const cmpParentName = parseUrl(location, 'parent')
  const componentName = parseUrl(location, 'noObs')
  const urlId = searchParams.get("id")
  const headerData = {
    mainHeading: 'Gallery',
    subHeadng: (urlId) ? 'Update Album' : 'Create Album',
    buttonText: []
  };

  const manageError = {
    albumtitle: "album title",
    // albumdescription: "album description",
    seotitle: "seo title",
    seokeywords: "seo key words",
    seodescription: "seo description",
    published: "published date",
    image: "featured image"
  }
  let initialInputState = {
    albumtitle: "",
    albumdescription: "",
    // seotitle: "",
    // seokeywords: "",
    // seodescription: "",
    visibility: "",
    published: "",
    writer: "",
    displayPosition:"",
    gallerytype: "image",
    image: "",
  }
  const [formInputs, setFormInputs] = useState(initialInputState);
  const [loadind, setLoading] = useState(false);
  const [previewImages, setPreviewImage] = useState([])
  const [previewId, setPreviewId] = useState("");

  useEffect(() => {
    if (urlId != null) {
      fetchPageData();
    }
  }, [urlId])

  const fetchPageData = async () => {
    setLoading(true);
    const res = await APIService.get(`/admin/gallery/albums/${urlId}`)
    if (res.status === 200) {
      let allData = res?.data?.data?.[0];
      let multiData = allData.multiData;
      let images = [];
      let imgArr = {};
      for (let i = 0; i < multiData.length; i++) {
        imgArr = {
          "id": i,
          "url": `${process.env.REACT_APP_API_URL}/` + multiData[i].url,
          "type": multiData[i].type,
          "title": multiData[i].title,
        }
        images.push(imgArr);
      }
      setPreviewImage(images)
      setFormInputs(allData);
      setPageData(prev => ({ ...prev, [imageId]: getImageUrl(allData?.image) }));
    }
    setLoading(false);
  }

  const itemId = getComponentItemId(mainData, cmpParentName, cmpName)

  const onFileChange = (event = null) => {
    if (event == null) {
      return false
    }
    let files = event.target.files
    if (files.length > 50) {
      toggleToast('danger', `Please select only 50 files.`)
      return false
    }
    let images = [];
    let imgArr = {};
    let fileType = "";
    for (let i = 0; i < event.target.files.length; i++) {
      let data = { 'id': i, 'url': URL.createObjectURL(event.target.files[i]), 'type': event.target.files[i].type, 'title': '' }
      // 'title': event.target.files[i].type
      images.push(data);
    }
    setPreviewImage(images)
    for (let i = 0; i < files.length; i++) {
      let type = files[i].type;
      if (type.includes("image")) {
        fileType = "image"
      } else {
        fileType = "video"
      }
      files[i]['id'] = i
      files[i]['icon'] = URL.createObjectURL(event.target.files[i])
      files[i]['fileType'] = fileType
      files[i]['title'] = fileType
    }
    setUploadFiles(files)
  }

  const handleUploadFile = (e) => {
    let key = e?.target?.name;
    let value = e?.target?.value;
    let uploadCopy = [...previewImages];
    uploadCopy[key].title = e.target.value;
    setPreviewImage(uploadCopy);
  }

  const handleInputChange = (e) => {
    let key = e?.target?.name;
    let value = e?.target?.value;
    if (!key) {
      return setFormInputs({ ...formInputs, ['albumdescription']: e });
    }
    else if (key === "ambassadors") {
      let val = Array.from(e.target.selectedOptions, option => option.value);
      return setFormInputs({ ...formInputs, ['ambassadors']: [...val] });
    }
    setFormInputs({ ...formInputs, [key]: value });
  }

  // Send this to server and get the url
  const multiFileUpload = async () => {
    let fileUrl = [];
    let filefailedUrl = [];
    let res = {}
    let i = 0;
    if (uploadfiles.length > 0) {
      for (let file of uploadfiles) {
        let formdata = new FormData();
        if (file.fileType == "image") {
          let data = new File([file], file.name, { type: "image/png" })
          formdata.append("media", data, data.name);
          res = await axios.post(`${process.env.REACT_APP_API_URL}/admin/page/upload`, formdata, { headers: { "Content-Type": "multipart/form-data" } })
        }
        else {
          let data = new File([file], file.name, { type: "video/mp4" })
          formdata.append("media", data, data.name);
          res = await axios.post(`${process.env.REACT_APP_API_URL}/admin/page/uploadvideo`, formdata, { headers: { "Content-Type": "multipart/form-data" } })
        }
        if (res.status === 200) {
          let result = { "url": res.data.path, "title": previewImages[i]?.title, "type": file.fileType }
          fileUrl.push(result)
        } else {
          filefailedUrl.push(file)
        }
        i++;
      }
      return fileUrl
    } else {
      if (previewImages.length > 0) {
        for (let pfile of previewImages) {
          let url = pfile.url.replace(`${process.env.REACT_APP_API_URL}/`, '');
          let result = { "url": url, "title": pfile.title, "type": pfile.type }
          fileUrl.push(result)
        }
        return fileUrl
      }
    }
  }
  const createAlbumCRUD = async (data) => {
    const res = await APIService.post(`/admin/gallery/albums`, data)
    if (res.status === 200) {
      if (res.data?.message) {
        toggleToast('success', res.data.message);
      }
      else {
        toggleToast('success', res.data);
      }
      setFormInputs(initialInputState);
      setPageData(prev => ({ ...prev, [imageId]: '' }))
      navigate(`/gallery/gallery-albums`);
    }
  }

  const addAlbum = async () => {
    let validation = formValidation(formInputs, "add");
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...formInputs
      }
      //upload images
      let multiData = await multiFileUpload();
      data['multiData'] = multiData
      let newSlug = generateSlug(formInputs.albumtitle);
      let validSlug = targetUrlValidator(newSlug);
      data['slug'] = validSlug.result;
      createAlbumCRUD(data);
    }
  }
  const formValidation = (obj, mode) => {
    let allowedKeys = ["seotitle", "seokeywords", "seodescription"];
    if(mode === "add"){
      allowedKeys.push("displayPosition")
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          if (allowedKeys.includes(key)) {
            return true;
          }
          if (manageError.hasOwnProperty(key)) {
            toggleToast('danger', `Please enter ${manageError[key]}`)
            return false;
          }
          toggleToast('danger', `Please enter ${key}`)
          return false;
        }
      }
    }
  }
  const reomveMedia = (id) => {
    let myNewArray = previewImages.filter((item) => item.id !== id);
    setPreviewImage(myNewArray)
  }

  const Preview_CRUD = async (data) => {
    let redirectTo = 'gallery';
    let slug = data.slug;
    if (previewId !== "") {
      data = { ...data, _id: previewId }
    };
    const res = await APIService.post(`/admin/gallery/preview`, data)
    if (res.status === 200) {
      let allData = res?.data?.data;
      setPreviewId(allData?._id);
      window.open(`${process.env.REACT_APP_FORNTEND_HOST}${redirectTo}?ispreview=true&previewslug=${slug}`, '_blank', 'windowFeatures');
    }
  }

  const addPreview = async () => {
    let validation = formValidation(formInputs);
    if (validation !== false) {
      let data = {
        "isActive": true,
        "published": false,
        "isDeleted": false,
        ...formInputs
      }
      //upload images
      let multiData = await multiFileUpload();
      data['multiData'] = multiData
      let newSlug = generateSlug(formInputs.albumtitle);
      let validSlug = targetUrlValidator(newSlug);
      data['slug'] = validSlug.result;
      Preview_CRUD(data);
    }
  }

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate=""
      />

      <Row>
        <Col md={8}>
          <Tabs
            defaultActiveKey="album"
          >
            <Tab eventKey="album" title="Album">
              <CardLayout>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="album_title"
                  >
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      name="albumtitle"
                      type="text"
                      value={(formInputs.albumtitle) ? formInputs.albumtitle : ''}
                      onChange={e => handleInputChange(e)}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="album_description"
                  >
                    <Form.Label>Description</Form.Label>
                    <TextEditor
                      value={formInputs.albumdescription ? formInputs.albumdescription : ""}
                      handleInputChange={(value) => handleInputChange(value)}
                    />
                  </Form.Group>

                </Form>
              </CardLayout>
            </Tab>
            <Tab eventKey="seo" title="SEO">
              <CardLayout>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="seo_title"
                  >
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      name="seotitle"
                      type="text"
                      value={formInputs.seotitle}
                      onChange={e => handleInputChange(e)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="seo_keywords"
                  >
                    <Form.Label>Keywords</Form.Label>
                    <Form.Control
                      name="seokeywords"
                      type="text"
                      value={formInputs.seokeywords}
                      onChange={e => handleInputChange(e)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="seo_description"
                  >
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      name="seodescription"
                      as="textarea"
                      rows={2}
                      style={{ resize: "none" }}
                      value={formInputs.seodescription}
                      onChange={e => handleInputChange(e)}
                    />
                  </Form.Group>
                </Form>
              </CardLayout>
            </Tab>
          </Tabs>
          <CardLayout>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Images/Video</Form.Label>
              {previewImages && (
                <div className="icon gridLayout">
                  {previewImages.map((img, i) => {
                    if (img.type.includes("image")) {
                      return (
                        <figure>
                          <button
                            type="button"
                            className="btn btn-danger btn-circle float-end"
                            title='Remove Media'
                            onClick={() => reomveMedia(img.id)}
                          >
                            X
                          </button>
                          <img className="preview multi-img-preview" height={100} width={100} src={img.url} alt={"image-" + i} key={i} />
                          <figcaption className="d-flex">
                            <div>
                              <div className="icon">
                                <div className="m-2">
                                  <input
                                    type="text"
                                    name={i}
                                    className="caption-width form-control"
                                    value={img.title ? img.title : ""}
                                    onChange={e => handleUploadFile(e)}
                                  />
                                </div>
                              </div>
                            </div>
                          </figcaption>
                        </figure>
                      );
                    } else {
                      return (
                        <figure>
                          <button
                            type="button"
                            className="btn btn-danger btn-circle float-end"
                            title='Remove Media'
                            onClick={() => reomveMedia(img.id)}
                          >
                            X
                          </button>
                          <video controls poster="/images/w3html5.gif" height={100} width={100}><source src={img.url} alt={"image-" + i} key={i} type="video/mp4" /></video>
                          <figcaption className="my-2">
                            <input
                              type="text"
                              name={i}
                              className="caption-width form-control"
                              value={img.title ? img.title : ""}
                              onChange={e => handleUploadFile(e)}
                            />
                          </figcaption>
                        </figure>
                      )
                    }
                  })}
                </div>
              )}
              <div className="form-control file_upload_block position-relative d-flex justify-content-center align-items-center flex-column multi-upload">
                <Form.Control
                  className="d-none"
                  type="file"
                  name="multifile"
                  accept="image/png,image/jpeg,video/mp4"
                  onChange={(e) => onFileChange(e)}
                  id="file"
                  data-multiple-caption="{count} files selected"
                  multiple
                />
                <label class="media-select-square fileDropBox true" for="file"><span class="content fileDropBox">Select Images or drag it here</span></label>

              </div>

            </Form.Group>
          </CardLayout>
        </Col>
        <Col md={4}>
          <CardLayout>
            <Form.Label column sm={3}>
              Publish
            </Form.Label>
            <Form.Group as={Row} className="mb-3" controlId="visibility">
              <Form.Label column sm={4}>Visibility</Form.Label>
              <Col sm={7}>
                <Form.Select name='visibility' onChange={e => handleInputChange(e)} >
                  <option selected disabled >Select Visibility...</option>
                  <option value="public" selected={(formInputs.visibility === 'public') ? true : false} >Public</option>
                  <option value="private" selected={(formInputs.visibility === 'none') ? true : false} >Private</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="published">
              <Form.Label column sm={4}>
                Published
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  // min={getCurrentDate()}
                  name="published"
                  type="date"
                  value={formInputs.published}
                  placeholder="yyyy/mm/dd"
                  onChange={e => handleInputChange(e)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="writer">
              <Form.Label column sm={4}>Writer</Form.Label>
              <Col sm={7}>
                <Form.Select name="writer" onChange={e => handleInputChange(e)} >
                  <option selected disabled >Select Writer...</option>
                  <option value="admin" selected={(formInputs.writer === 'admin') ? true : false} >Admin</option>
                  <option value="user" selected={(formInputs.writer === 'user') ? true : false}>User</option>
                  <option value="other" selected={(formInputs.writer === 'other') ? true : false}>Other</option>
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="writer">
              <Form.Label column sm={4}>Gallery Type</Form.Label>
              <Col sm={7}>
                <Row>
                  <Col sm={6}>
                    <Form.Check
                      type='radio'
                      label={`Image`}
                      value="image"
                      name="gallerytype"
                      checked={(formInputs?.gallerytype === "image") ? true : false}
                      onChange={e => handleInputChange(e)}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Check
                      type='radio'
                      label={`Video`}
                      value="video"
                      name="gallerytype"
                      checked={(formInputs?.gallerytype === "video") ? true : false}
                      onChange={e => handleInputChange(e)}
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
            {state && (
              <Form.Group as={Row} className="mb-3" controlId="writer">
              <Form.Label column sm={4}>Display Position</Form.Label>
              <Col sm={7}>
                <Form.Control 
                name="displayPosition" 
                type="number" 
                value={formInputs?.displayPosition}
                onChange={e => handleInputChange(e)} >
                  
                </Form.Control>
              </Col>
            </Form.Group>)}
            
            <Row>
              <Col>
                <Button variant='success' onClick={addPreview}>
                  <FaEye className='me-2' />
                  Preview
                </Button>
              </Col>
              <Col>
                <Button onClick={addAlbum} ><FaTelegramPlane className='me-2' />Publish</Button>
              </Col>
            </Row>
          </CardLayout>
          <CardLayout>
            <Form.Group
              className="mb-3"
              controlId="featured-image"
            >
              <Form.Label>Featured Image</Form.Label>
              <SelectMedia
                type={'image'}
                name="image"
                id={imageId}
                imgSrc={(formInputs.image) ? formInputs?.image : ''}
                updateInputState={setFormInputs}
              />
            </Form.Group>
          </CardLayout>
        </Col>
      </Row>
    </>
  );
};

export default CreateAlbums;
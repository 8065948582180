import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Row, Tabs, Tab } from 'react-bootstrap';
import CardLayout from '../../components/Layout/CardLayout';
import SeoTab from '../../components/Shared/components2/SeoTab';
import SelectMedia from '../../components/Shared/Media/SelectMedia';
import PageHeader from '../../components/Shared/Section/PageHeader';
import useToasts from '../../components/Toast/toastProvider';
import { DataContext } from '../../context/CustomGlobalContext';
import APIService from '../../utils/APIService';
import { getImageUrl } from '../../utils/Helper';

const Home = () => {
  const { setPageData } = useContext(DataContext);

  const headerData = {
    mainHeading: 'Home - Overview',
    subHeadng: '',
    buttonText: ['Update'],
  };
  const { toggleToast } = useToasts();
  const [localdata2, setLocalData2] = useState({
    head: '',
    subhead: '',
    image: '',
  });
  const [seoData, setSeoData] = useState({
    seoTitle: '',
    seoKeywords: '',
    seoDescription: '',
  });
  const [pageDataApiResponse, setPageDataApiResponse] = useState();
  let pagename = 'homeOverview';
  let imageId = 'home_image';

  useEffect(() => {
    fetchPageData();
  }, []);

  const submitData = async () => {
    let payload = { ...pageDataApiResponse };
    payload.overview = localdata2;
    payload.seo = seoData;
    // call the put api for the data update
    const res = await APIService.put(`/admin/page/${pagename}`, { payload });
    if (res.status === 200) {
      toggleToast('success', 'Page updated successfully ');
    } else {
      toggleToast('failure', 'Page not updated successfully ');
    }
  };

  const handleValueChange = (e, field) => {
    setLocalData2({ ...localdata2, [field]: e.target.value });
  };

  const fetchPageData = async () => {
    const res = await APIService.get(`/admin/page/item?pagename=${pagename}`);
    if (res.status === 200) {
      let allData = res.data?.data?.[0];
      let data = allData?.overview;
      setPageDataApiResponse(allData);
      setLocalData2(data);
      setPageData(allData);
      if (allData?.seo) {
        setSeoData(allData?.seo);
      }
      setPageData(prev => ({ ...prev, [imageId]: getImageUrl(data?.image) }));
    }
  };

  return (
    <>
      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={submitData}
      />
      <>
        <CardLayout>
          <Tabs defaultActiveKey='main-content'>
            <Tab eventKey='main-content' title='Home'>
              <Row>
                <Col md={8}>
                  <Form>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                      <Form.Label>Head</Form.Label>
                      <Form.Control
                        type='text'
                        value={localdata2?.head ? localdata2.head : ''}
                        name='head'
                        onChange={e => handleValueChange(e, 'head')}
                      />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                      <Form.Label>Sub-head</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={2}
                        style={{ resize: 'none' }}
                        name='subhead'
                        value={localdata2.subhead ? localdata2.subhead : ''}
                        onChange={e => handleValueChange(e, 'subhead')}
                      />
                    </Form.Group>
                  </Form>
                </Col>
                <Col md={3} className='d-flex justify-content-center align-self-center offset-md-1'>
                  <SelectMedia
                    type={'image'}
                    name='image'
                    id={imageId}
                    imgSrc={localdata2?.image ? localdata2?.image : ''}
                    updateInputState={setLocalData2}
                  />
                </Col>
              </Row>
            </Tab>
            <Tab eventKey='seo' title='SEO'>
              <SeoTab
                formInputs={seoData}
                handleInputChange={e => {
                  let field = e.target?.name;
                  let copyData = { ...seoData };
                  copyData[field] = e.target?.value;
                  setSeoData(copyData);
                }}
              />
            </Tab>
          </Tabs>
        </CardLayout>
      </>
    </>
  );
};

export default Home;

import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, Row, Tab, Tabs } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom'
import CardLayout from '../../components/Layout/CardLayout'
import SelectMedia from '../../components/Shared/Media/SelectMedia'
import PageHeader from '../../components/Shared/Section/PageHeader'
import useToasts from '../../components/Toast/toastProvider'
import { DataContext } from '../../context/CustomGlobalContext'
import APIService from '../../utils/APIService'
import { getImageUrl, targetUrlValidator } from '../../utils/Helper'
import TextEditor from "../../components/Shared/components/TextEditor"
import SeoTab from '../../components/Shared/components2/SeoTab'

const PageContent = () => {

  const { toggleToast } = useToasts();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pagedata, setPageData } = useContext(DataContext);
  const rowId = searchParams.get('id') || '';

  const defaultSection = {
    details: "",
    align: "left",
    imageUrl: ""
  };
  const defaultOverview = {
    showoverview: true,
    head: '',
    subhead: '',
    pagetitle: '',
    image: ''
  };
  const [localdata2, setLocalData2] = useState(defaultOverview);
  const [slug, setSlug] = useState("");
  const [previewId, setPreviewId] = useState("");

  const imageId1 = `customPage_image_section1`;
  const imageId2 = `customPage_image_section2`;
  const [pageDataApiResponse, setPageDataApiResponse] = useState({
    overview: defaultOverview,
    slug: slug,
    sections: [defaultSection],
  });
  const [seoData, setSeoData] = useState({
    seoTitle: '',
    seoKeywords: '',
    seoDescription: '',
  });

  const headerData = {
    mainHeading: "Page",
    subHeadng: "Create",
    buttonText: ['Update']
  };

  useEffect(() => {
    function resetCotextData(obj, condition) {
      const result = {};
      for (const key in obj) {
        if (key.includes(condition)) {
          result[key] = '';
        }
      }
      return result;
    }
    const result = resetCotextData(pagedata, `customPage_image_section`);
    const final = { ...pagedata, ...result };
    setPageData(final);
  }, [])

  useEffect(() => {
    setLocalData2(defaultOverview);
    if (rowId) {
      fetchPageData();
    }
  }, [rowId])

  const handleInputChange1 = (e, field, index) => {
    let pageData = { ...pageDataApiResponse };
    if (field === "checkbox") {
      pageData.sections[index] = { ...pageData.sections[index], [e.target.name]: e.target.checked };
    } else {
      pageData.sections[index] = { ...pageData.sections[index], [e.target.name]: e.target.value };
    }
    setPageDataApiResponse(pageData);
  };

  const handleInputChangeForEditor = (value, index) => {
    let pageData = { ...pageDataApiResponse };
    pageData.sections[index] = { ...pageData.sections[index], ['details']: value };
    setPageDataApiResponse(pageData);
  }

  const fetchPageData = async () => {
    let dataId = (rowId) ? `/${rowId}` : '';
    const res = await APIService.get(`/admin/custom-page${dataId}`);
    if (res.status === 200) {
      let apiResponse = res?.data?.data;
      let finalObj = {};
      if (apiResponse?.length) {
        let allData = apiResponse?.[0];
        let overviewData = (allData?.overview) ? allData?.overview : defaultOverview;
        setSlug(allData.slug);
        setLocalData2(overviewData);
        if (allData?.seo) {
          setSeoData(allData?.seo);
        }
        allData[`${imageId1}`] = getImageUrl(allData?.overview?.image);
        if (!allData?.sections?.length) {
          allData.sections = [defaultSection];
        }
        finalObj = allData;
        allData.sections.map((val, i) => {
          allData[`${imageId2}-${i}`] = getImageUrl(val?.imageUrl);
        });
        setPageData(allData);
      }
      else {
        finalObj = {
          overview: defaultOverview,
          slug: slug,
          sections: [defaultSection],
        }
      }
      setPageDataApiResponse(finalObj);
    }
  };

  const submitOverviewData = async () => {
    let payload = {};
    payload.overview = localdata2;
    payload.seo = seoData;
    payload.slug = slug;
    if (rowId) payload._id = rowId;
    let validation = formValidation(localdata2);
    if (validation !== false) {
      pageCRUD(payload);
    }
  }

  const submitData = async () => {
    let payload = { sections: pageDataApiResponse.sections };
    if (!rowId) {
      payload.overview = localdata2;
      payload.slug = slug;
      let validation = formValidation(localdata2);
      if (validation !== false) {
        pageCRUD(payload);
      }
    } else {
      payload._id = rowId;
      pageCRUD(payload);
    }
  };

  const pageCRUD = async (payload) => {
    const res = await APIService.put(`/admin/custom-page/create`, payload);
    if (res.status === 200) {
      if (res?.data?.data?._id) {
        setSearchParams({ id: res?.data?.data?._id });
      }
      toggleToast("success", "Page updated successfully ");
    } else {
      toggleToast("danger", res?.data?.message);
    }
  };

  const addNewSection = () => {
    let oldpageData = { ...pageDataApiResponse };
    let addNewSection = defaultSection
    let index = pageDataApiResponse?.sections.length;
    oldpageData[`${imageId1}-${index}`] = getImageUrl("");
    oldpageData.sections.push(addNewSection);
    setPageDataApiResponse(oldpageData);
    // setPageData({...pageData,sections:oldpageData})
  };

  const updatePageState = (updateSection, index) => {
    let pageData = { ...pageDataApiResponse };
    let updatedSection = updateSection(pageData.sections[index]);
    pageData.sections[index] = updatedSection;
    setPageDataApiResponse(pageData);
  };

  const deleteSection = (index) => {
    const confirmBox = window.confirm(
      "Do you really want to delete this section?"
    )
    if (confirmBox === true) {
      let oldpageData = { ...pageDataApiResponse };
      let sectionFilter = oldpageData.sections.filter((val, i) => (i !== index));
      oldpageData.sections = sectionFilter;
      setPageDataApiResponse(oldpageData);
    }
  }

  const formValidation = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === undefined) {
          if (key === 'image') {
            if (obj.showoverview === false) {
              if (slug === '') {
                toggleToast('danger', `Please enter page link`);
                return false;
              }
              return true;
            }
          } else if (key === 'subhead' || key === 'pagetitle') {
            continue;
          } else {
            toggleToast('danger', `Please enter ${key}`);
            return false;
          }
        }
      }
    }
    if (slug === '' || slug === undefined) {
      toggleToast('danger', `Please enter page link`);
      return false;
    }
  }

  const submitPreviewData = async () => {
    let payload = {
      overview: localdata2,
      slug: slug,
      sections: pageDataApiResponse.sections
    };
    let validation = formValidation(localdata2);
    if (validation !== false) {
      previewCRUD(payload);
    }
  };

  const previewCRUD = async (data) => {
    let redirectTo = `pages/${data?.slug}`;
    let slug = data?.slug;
    if (previewId !== "") {
      data = { ...data, _id: previewId }
    };
    const res = await APIService.post(`/admin/custom-page/preview`, data)
    if (res.status === 200) {
      let allData = res?.data?.data;
      setPreviewId(allData?._id);
      window.open(`${process.env.REACT_APP_FORNTEND_HOST}${redirectTo}?ispreview=true&previewslug=${slug}`, '_blank', 'windowFeatures');
    }
  }

  const deleteMedia = (mediaId, mediaName, index) => {
    if (index === 'overview') {
      localdata2[mediaName] = '';
      setPageData(prev => {
        return { ...prev, [mediaId]: '' }
      });
    }
    else {
      pageDataApiResponse.sections[index][mediaName] = '';
      setPageData(prev => {
        return { ...prev, [mediaId]: '' }
      });
    }
  }

  return (
    <>

      <PageHeader
        mainHeading={headerData.mainHeading}
        subHeadng={headerData.subHeadng}
        buttonText={headerData.buttonText[0]}
        handleUpdate={submitOverviewData}
      />
      <>
      <Tabs defaultActiveKey='main-content'>
        <Tab eventKey='main-content' title='Page Content'>
        <CardLayout>
          <Row>
            <Col md={9}>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Head </Form.Label>
                  <Form.Control
                    type="text"
                    value={localdata2?.head ? localdata2.head : ''}
                    name="head"
                    onChange={e => setLocalData2({ ...localdata2, ['head']: e.target.value })}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Sub - head </Form.Label>
                  <Form.Control
                    style={{ resize: "none" }}
                    name="subhead"
                    value={localdata2.subhead ? localdata2.subhead : ''}
                    onChange={e => setLocalData2({ ...localdata2, ['subhead']: e.target.value })}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.PageTitle"
                >
                  <Form.Label>Page Title </Form.Label>
                  <Form.Control
                    type="text"
                    value={localdata2?.pagetitle ? localdata2.pagetitle : ''}
                    name="pagetitle"
                    onChange={e => setLocalData2({ ...localdata2, ['pagetitle']: e.target.value })}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.Slug"
                >
                  <Form.Label>Page link</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      /pages/
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      disabled={(rowId) ? true : false}
                      value={slug}
                      name="slug"
                      onChange={
                        e => {
                          let validValue = targetUrlValidator(e.target.value);
                          if (validValue?.message !== "") {
                            toggleToast('danger', validValue?.message);
                          }
                          return setSlug(validValue.result)
                        }
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </Form>
            </Col>
            <Col
              md={3}
              className='text-end'
            >
              <Form.Check
                type="checkbox"
                name="showoverview"
                checked={localdata2?.showoverview}
                label={`Show Header`}
                onClick={() => setLocalData2(prev => ({ ...prev, showoverview: !prev.showoverview }))}
              />
              <div className='mt-5'>
                {
                  (localdata2?.image && localdata2?.showoverview === false) &&
                  <button button
                    type="button"
                    className="btn btn-danger btn-circle float-end mb-3"
                    title='Remove Media'
                    onClick={() => deleteMedia(imageId1, 'image', 'overview')}
                  >
                    X
                  </button>
                }
                <SelectMedia
                  type={'image'}
                  name="image"
                  id={imageId1}
                  imgSrc={(localdata2?.image) ? localdata2?.image : ''}
                  updateInputState={setLocalData2}
                />
              </div>
            </Col>
          </Row>
        </CardLayout>
        </Tab>
        <Tab eventKey='seo' title='SEO'>
          <SeoTab
            formInputs={seoData}
            handleInputChange={e => {
              let field = e.target?.name;
              let copyData = { ...seoData };
              copyData[field] = e.target?.value;
              setSeoData(copyData);
            }}
          />
        </Tab>
        </Tabs>
        <hr />
        {/* <PageHeader
          buttonText={headerData.buttonText[0]}
          handleUpdate={submitData}
        /> */}
        <div className="w-100" >
          <Button
            className="ms-1 float-end"
            variant='primary'
            size='sm'
            onClick={submitData}
          >
            Update
          </Button>
          <Button
            className="me-1 float-end"
            variant='success'
            size='sm'
            onClick={submitPreviewData}
          >
            Preview
          </Button>
          <br />
          <br />
        </div>

        {pageDataApiResponse?.sections &&
          pageDataApiResponse?.sections.length > 0 &&
          pageDataApiResponse?.sections.map((localdata, index) => (
            <>
              <CardLayout>
                <Row>
                  <Col md={9}>
                    <Form>
                      <Form.Group
                        className='mb-3'
                        controlId='exampleForm.ControlInput1'
                      >
                        <Form.Label>Details</Form.Label>
                        <TextEditor
                          value={localdata.details ? localdata.details : ""}
                          handleInputChange={(value) => handleInputChangeForEditor(value, index)}
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col
                    md={3}
                    className='text-end'
                  >
                    <div className='row' >
                      <Form.Select
                        className='w-75 me-4'
                        name="align"
                        size="sm"
                        value={localdata?.align ? localdata.align : ''}
                        onChange={e => handleInputChange1(e, "align", index)}
                      >
                        <option disabled value="">
                          {" "}
                          --Align--{" "}
                        </option>
                        <option value="left">Left</option>
                        <option value="right">Right</option>
                      </Form.Select>
                      {
                        localdata?.imageUrl &&
                        <button
                          type="button"
                          className="btn btn-danger btn-circle float-end"
                          title='Remove Media'
                          onClick={() => deleteMedia(`${imageId2}-${index}`, 'imageUrl', index)}
                        >
                          X
                        </button>
                      }
                    </div>
                    <div className='mt-5'>
                      <SelectMedia
                        type={'image'}
                        name="imageUrl"
                        id={`${imageId2}-${index}`}
                        imgSrc={(localdata?.imageUrl) ? localdata?.imageUrl : ''}
                        updateInputState={(data) => updatePageState(data, index)}
                      />
                    </div>
                  </Col>
                </Row>
                {
                  index && index > 0 && <Button onClick={e => deleteSection(index)} className="mt-3" variant="danger" >Delete Section</Button> || ''
                }
              </CardLayout>
            </>
          ))}

        <div className="add_section" onClick={addNewSection}>
          <span>add section</span> <span className="add_icon">+</span>
        </div>

      </>
    </>
  )
}

export default PageContent;